import { purple, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core';
import { deDE } from '@material-ui/core/locale';

// A custom theme for this app
// see https://material-ui.com/customization/color/#color-tool
// see https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=102F59&secondary.color=BBDEFB

const commonThemeOptions = {
  spacing: 4,
  props: {
    MuiContainer: {
      // hack: avoid the left and right margin on every container
      disableGutters: true,
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        padding: '4px 8px',
        '& :lastChild': '16px',
        // backgroundColor: 'lightblue',
      },
    },
    MuiCardHeader: {
      root: {
        padding: '8px',
        // backgroundColor: 'lightgreen',
      },
    },
  },
};

export const darkThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      // dark: '#0b203e',
      main: '#102F59', // linde blau
      // light: '#3f587a',
      contrastText: '#fff',
    },
    secondary: {
      main: purple[`900`],
      // main: '#102F59', // linde blau
      contrastText: '#fff',
    },
    accent: { backgroundColor: '#102F59', color: '#fff' },
  },
  ...commonThemeOptions,
};

export const darkTheme = createTheme(darkThemeOptions, deDE);

export const lightTheme = createTheme(
  {
    palette: {
      type: 'light',
      primary: {
        main: '#102F59', // linde blau
        contrastText: '#fff',
      },
      secondary: {
        main: red['400'],
        contrastText: '#fff',
      },
      accent: { backgroundColor: '#102F59', color: '#fff' },
    },
    overrides: {
      MuiButton: {
        root: {
          color: '#fff',
          raisedPrimary: {
            color: '#fff',
          },
        },
      },
    },
    ...commonThemeOptions,
  },
  deDE
);
