import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';

export const statusList = Object.fromEntries(
  ['bereits bezahlt', 'GU', 'GU m NB', 'ToDo Bochum', 'ToDo Kunde', 'ToDo Oheim', 'ToDo Rumänien', 'VS', null].map((x) => [x, x])
); // single
// noinspection SpellCheckingInspection
export const errorReasons = Object.fromEntries(
  ['ARZ', 'FM', 'IPS', 'KK', 'Komp', 'OptaData', 'Optica', 'Patient', 'Timisoara', 'VM', null].map((x) => [x, x])
);
// const kkNames = Object.fromEntries(['DAK', 'BEK', 'AOK Nordost', 'AOK Bayern'].map((x) => [x, x]));

export const EditGrund = (props) => {
  return (
    <TextField
      // minRows={3}
      // maxRows={7}
      id='edit-grund-in-table'
      label='Grund'
      InputProps={{ style: { fontSize: 14 } }}
      multiline
      fullWidth
      defaultValue={props.value || ''}
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
};
EditGrund.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
EditGrund.defaultProps = {
  value: '',
  onChange: (e) => console.log('EditGrund', e),
};

EditGrund.displayName = 'EditGrund';
