import MaterialTable, { MTableBodyRow } from '@material-table/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, red } from '@material-ui/core/colors';
import Input from '@material-ui/core/Input';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import WarningIcon from '@material-ui/icons/Warning';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React, { useEffect, useState } from 'react';
import { addId } from '../helpers/backend-helpers';
import { EditGrund, errorReasons, statusList } from '../helpers/ClaimPositionHelpers';
import { withEither, withMaybe } from '../helpers/functional';
import {
  currencyFormat,
  currencySetting,
  getNavigatorLanguage,
  localization,
  percentageToHsl,
  tableIcons,
} from '../helpers/material-table-helpers';

const navLang = getNavigatorLanguage();
const intlDateTimeOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};
const intlDateTimeFormat = new Intl.DateTimeFormat(navLang, intlDateTimeOptions);

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
    },
    normRow: {
      backgroundColor: theme.palette.secondary.main,
    },
    highRow: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  loading: {
    // display: 'flex',
    display: 'inline-block',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '200px',
    height: '100px',
    margin: 'auto',
    backgroundColor: theme.palette.secondary.main,
  },
}));

const clusterClaims = Object.fromEntries(
  [
    'Abgelehnt',
    'Abrechnungsfrist',
    'Doppelberechnung',
    'DTA / Ebilling / Datensatz falsch',
    'Erst RE fehlt bei Kasse',
    'Erstlieferung war später',
    'falschen Vertrag / Versorgung ausgewählt',
    'Falscher Debitor',
    'Genehmigung fehlt / falsch',
    'Genehmigungsnummer fehlt / falsch',
    'Geräte wurden bereits abgeholt',
    'GOX Mehrbedarf',
    'Hinweis zur Erst VO fehlt',
    'HMV-Nr. / Himi-Nr. / Produktbesonderheit fehlt /falsch',
    'IK falsch',
    'in Pauschale enthalten',
    'Kassenspez. Anlage fehlt',
    'keine Lieferung / Krankenhausaufenthalt',
    'keine Mitgliedschaft / KK Wechsel',
    'Klinikanschrift falsch',
    'Kopie Rezept fehlt / falsch',
    'LEGS / LEB / ACTK fehlt / falsch',
    'Leistung vor Austellung der Verordnung',
    'Leistungskennzeichen / Himikennzeichen fehlt / falsch',
    'Lieferschein fehlt / falsch',
    'Menge falsch',
    'MIP / Poolbuchung fehlt',
    'Original Rezept fehlt / falsch',
    'Pat verstorben',
    'Preis falsch',
    'pro Genehmigung eine Rechnung',
    'Rechnung ohne Anlagen VS',
    'Rezept falsch ausgestellt / Rezeptdaten falsch',
    'Rückholung liegt vor Vetrag nicht abgeschlossen',
    'Therapieabbruch',
    'Umversorgung auf andere Therapie',
    'Unterschrift auf LS fehlt',
    'Versicherungsnummer falsch',
    'Versorgung durch anderen Anbieter',
    'Versorgungszeitraum falsch',
    'Zuzahlung falsch',
  ].map((x) => [x, x])
);

const EditMultiSelect = (props) => {
  const makeMenuItem = (value, label, key) => (
    <MenuItem key={key} value={value}>
      {label}
    </MenuItem>
  );
  const entries = Object.entries(props.columnDef.lookup).map(([k, v], i) => makeMenuItem(k, v, i));
  console.log('EditMultiSelect render', entries, props.value);
  if (!props.value) props.onChange([]);
  else if (props.value && !(props.value instanceof Array)) props.onChange([props.value]);
  return (
    <>
      <Select
        id={props.columnDef?.field + 'Edit'}
        multiple
        value={props.value}
        // onChange={(e) => console.log('select: ', e, props.value)}
        onChange={(e) => props.onChange(e.target.value)}
        input={<Input name={props.columnDef?.field} />}
      >
        {entries}
      </Select>
    </>
  );
};
EditMultiSelect.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  columnDef: PropTypes.shape({
    lookup: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
  }),
};
EditMultiSelect.defaultProps = {
  value: ['None'],
};
EditMultiSelect.displayName = 'EditMultiSelect';

function LinearProgressWithLabel(props) {
  const { width, ...rest } = props;
  const color = percentageToHsl(props.value / 100, 0, 120);
  const useStyles = makeStyles({
    root: {
      height: 15,
      borderRadius: 3,
    },
    // colorPrimary: {
    //   backgroundColor: 'black',
    // },
    barColorPrimary: {
      backgroundColor: color,
    },
  });
  const classes = useStyles();
  // console.debug('LinearProgressWithLabel', styles);
  return (
    <Box display='flex' alignItems='center'>
      <Box width={width || '100%'} mr={1}>
        <LinearProgress classes={classes} variant='determinate' {...rest} />
      </Box>
      <Box minWidth={'3rem'}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  /**
   * a css width argument.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const TableTitle = (props) => {
  const { infos, rows } = props;
  let checkedText;
  if (rows > 0) {
    checkedText = `Geprüft: ${infos.checkedCount} von ${rows || '0'}`;
  } else {
    checkedText = 'Keine Einträge.';
  }
  return (
    <Box display='flex'>
      <Typography display='inline' variant={'body1'} pr={2}>
        Summe Korrekturen: {currencyFormat(infos.korrekturSum)}
      </Typography>
      <Box px={10}>
        <Typography display='inline' variant={'body1'} pr={2}>
          {checkedText}
        </Typography>
      </Box>
      {rows > 0 ? <LinearProgressWithLabel value={(infos.checkedCount / rows) * 100} width={'25vw'} /> : null}
    </Box>
  );
};
TableTitle.propTypes = {
  infos: PropTypes.shape({
    checkedCount: PropTypes.number,
    korrekturSum: PropTypes.number,
  }).isRequired,
  rows: PropTypes.number.isRequired,
};

const ClaimPositionsTableRaw = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      title: 'id',
      field: 'id',
      hidden: true,
      hiddenByColumnsButton: true,
      editable: 'never',
    },
    {
      title: 'Geprüft',
      field: 'checked',
      editable: 'always',
      type: 'boolean',
      render: function renderChecked(rowData) {
        const checked = rowData.checked;
        if (checked === undefined) return <PanoramaFishEyeIcon color='disabled' />;
        if (checked === true) return <CheckCircleIcon style={{ color: green[500] }} />;
        if (checked === false) return <ErrorIcon style={{ color: red[500] }} />;
        return <WarningIcon color='secondary' />;
      },
      cellStyle: {
        maxWidth: '3rem',
      },
      headerStyle: {
        maxWidth: '5rem',
      },
    },
    {
      title: 'Status',
      field: 'status',
      editable: 'always',
      filtering: true,
      lookup: statusList,
    },
    {
      title: 'Fehler erzeugt',
      field: 'error_from',
      editable: 'always',
      filtering: true,
      lookup: errorReasons,
      // eslint-disable-next-line react/display-name
      // render: (rowData) => <MaterialTableListRender value={rowData || null} col={'error'} />,
      // eslint-disable-next-line react/display-name
      // editComponent: (props) => <EditMultiSelect {...props} />,
      // customFilterAndSearch: (term, rowData) => term.every((val) => rowData.error.includes(val)), // term == rowData.error.length
    },
    {
      title: 'Grund',
      field: 'clusterClaim',
      editable: 'always',
      filtering: true,
      lookup: clusterClaims,
    },
    {
      title: 'Beleg',
      field: 'beleg',
      editable: 'always',
      grouping: false,
    },
    {
      title: 'Name',
      field: 'name',
      editable: 'always',
      grouping: true,
    },
    /*
     {
     title: 'KV Nummer',
     field: 'kv_nummer',
     editable: 'always',
     },
     */
    /*
     {
     title: 'Geburtsdatum',
     field: 'geburtsdatum',
     type: 'date',
     editable: 'always',
     },
     */
    {
      title: 'Rechnung Nr',
      field: 'rechnung_nr',
      editable: 'always',
    },
    /*
     {
     title: 'Pic-Nr',
     field: 'picnr',
     editable: 'always',
     },
     // DAK zeugs, eigentlich überflüssig.
     {
     title: 'Art.-Pos.-Nr.',
     field: 'artPosNr',
     editable: 'always',
     },
     {
     title: 'Pos.',
     field: 'pos',
     editable: 'always',
     type: 'numeric',
     cellStyle: {
     width: '2rem',
     maxWidth: '3rem',
     },
     headerStyle: {
     width: '2rem',
     maxWidth: '2rem',
     },
     },
     */
    /*
     {
     title: 'Netto',
     field: 'netto',
     editable: 'never',
     type: 'currency',
     currencySetting: currencySetting,
     cellStyle: {
     width: '2rem',
     maxWidth: '3rem',
     },
     },
     {
     title: 'Brutto',
     field: 'brutto',
     editable: 'never',
     type: 'currency',
     currencySetting: currencySetting,
     cellStyle: {
     width: '2rem',
     maxWidth: '3rem',
     },
     },
     */
    {
      title: 'Korrektur',
      field: 'korrektur',
      editable: 'always',
      type: 'currency',
      currencySetting: currencySetting,
    },
    {
      title: 'Erste Bearbeitung',
      field: 'startTime',
      type: 'date',
      dateSetting: { locale: navLang },
      editable: 'onUpdate',
      // eslint-disable-next-line react/display-name
      // editComponent: (props) => <MTDateRender {...props} />,
    },
    {
      title: 'finale Bearbeitung',
      field: 'endTime',
      type: 'date',
      dateSetting: { locale: navLang },
      editable: 'onUpdate',
    },
    {
      title: 'last Modified',
      field: 'lastModified',
      type: 'datetime',
      render: (rowData) =>
        rowData.lastModified instanceof Date ? intlDateTimeFormat.format(rowData.lastModified) : rowData.lastModified,
      editable: 'never',
    },
    {
      title: 'Grund (Text)',
      field: 'grund',
      editable: 'onAdd',
      cellStyle: {
        width: '25rem',
        minWidth: '21rem',
      },
      editComponent: EditGrund,
    },
    {
      title: 'Bemerkung',
      field: 'notes',
      editable: 'always',
      filtering: true,
    },
  ];

  const [state, setState] = useState({
    columns: [...columns],
    data: [...props.positionsList],
  });
  const [extraInfo, setExtraInfo] = useState({
    korrekturSum: -1,
    checkedCount: -1,
  });

  /*
   const updateCheckeClaimsCount = useCallback(async () => {
   const sum = state.data.reduce((a, b) => a + (b['checked'] === true ? 1 : 0), 0);
   console.debug(`updateCheckeClaimsCount: ${sum}, pos List:`, state.data);
   return sum;
   }, [state.data]);
   */

  useEffect(() => {
    console.log('ClaimPositionsTableRaw useEffect', state.data);
    const updateCheckeClaimsCount = (rows) => {
      // console.debug(`updateCheckeClaimsCount: ${cnt}, pos List:`, rows);
      return rows.reduce((a, b) => a + (b['checked'] === true ? 1 : 0), 0);
    };
    const updateKorrekturSum = (rows) => {
      // console.debug(`updateKorrekturSum: ${sum}, pos List:`, rows);
      return rows.reduce((a, b) => a + (b['korrektur'] || 0), 0);
    };
    const sum = updateKorrekturSum(state.data);
    // noinspection JSCheckFunctionSignatures
    // .then((sum) => setExtraInfo((prevState) => ({ ...prevState, korrekturSum: sum })));
    const cnt = updateCheckeClaimsCount(state.data);
    // .then((sum) => setExtraInfo((prevState) => ({ ...prevState, checkedCount: sum })));
    setExtraInfo({ korrekturSum: sum, checkedCount: cnt });
    console.log('ClaimPositionsTableRaw useEffect ende');
    return () => {
      console.log('ClaimPositionsTableRaw useEffect cleanup');
    };
  }, [state.data]);

  const actionPosChecked = (rowData) => {
    console.log('actionPosChecked', rowData.checked, rowData.name);
    const { tableData, ...newData } = rowData;
    // toggle checked flag for DB.
    newData['checked'] = !newData['checked'];
    props.updateRow(newData, state.data[tableData.id]).then((res) => {
      console.log('onRowUpdate then', res);
      if (res) {
        // update table in browser
        const merged = state.data.map((item, index) => {
          if (index !== rowData.tableData.id) return item;
          // toggle checked flag in changed row
          return { ...item, checked: !item.checked };
        });
        setState({ ...state, data: merged });
        // enqueueSnackbar(`Status auf ${newData['checked'] ? 'geprüft' : 'ungeprüft'} gesetzt`, { variant: 'info' });
        console.log(`Updated row ${rowData.tableData.id}`);
      } else {
        console.log(`Update status failure for ${rowData.tableData.id}`);
      }
    });
  };
  console.log('render state:', state);
  if (!state.data) {
    console.log('Aua. no data?');
    return null;
  }
  return (
    <>
      {/*<pre>{JSON.stringify(props.positionsList, null, 2)}</pre>*/}
      {/*
       <Typography variant={'body1'}>Summe Korrekturen: {currencyFormat(state.korrekturSum)}</Typography>
       <Typography variant={'body1'}>
       Geprüft: {state.checkedCount} von {state.data.length}
       </Typography>
       <LinearProgressWithLabel value={(state.checkedCount / state.data.length) * 100} />
       */}
      <MaterialTable
        icons={tableIcons}
        className={classes.root}
        // onRowClick={(evt, selectedRow) => {
        //   console.log('click');
        //   setSelectedRow(selectedRow.tableData.id);
        // }}
        options={{
          paging: true,
          pageSize: 100,
          // pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000, { value: state.data.length, label: 'All' }],
          pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000, 10000],
          emptyRowsWhenPaging: false,
          paginationPosition: 'top',
          padding: 'dense',
          showTitle: true,
          toolbar: true,
          grouping: true,
          filtering: true,
          search: true,
          addRowPosition: 'first',
          rowStyle: {
            // fontSize: '1rem',
            verticalAlign: 'top',
          },
          headerStyle: { position: 'sticky', top: 0 },
          // CSS doesnt like to many numbers...
          maxBodyHeight: `calc(100vh - ${72 + 208 + 53 + 64 + 56}px)`,
          // maxBodyHeight: '100%',
        }}
        localization={localization}
        data={state.data}
        columns={state.columns}
        actions={[
          {
            icon: tableIcons['Export'],
            isFreeAction: true,
            tooltip: 'Download Liste',
            onClick: (event) => props.exportList(event),
          },
          (rowData) => {
            return {
              icon: rowData.checked ? tableIcons['Checked'] : tableIcons['Help'],
              tooltip: rowData.checked ? 'Geprüft' : 'Ungeprüft',
              onClick: (event, row) => actionPosChecked(row),
            };
          },
        ]}
        editable={{
          onRowUpdateCancelled: (rowData) => {
            enqueueSnackbar('Edit canceled.', { variant: 'info' });
            console.log('Row editing cancelled', rowData);
          },
          onRowAddCancelled: (rowData) => {
            enqueueSnackbar('Add canceled.', { variant: 'info' });
            console.log('Row adding cancelled', rowData);
          },
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              props
                .addRow(newData)
                .then((res) => {
                  if (res) {
                    let added;
                    [added] = addId([newData]);
                    // console.log('added row', added);
                    const dataUpdate = [...state.data];
                    dataUpdate.unshift(added);
                    console.log('dataUpdate', dataUpdate);
                    setState((prevState) => ({ ...prevState, data: dataUpdate }));
                    resolve();
                  } else {
                    reject();
                  }
                })
                .catch(() => reject());
            }),
          onRowUpdate: (newData, oldData) => {
            const dataUpdate = [...state.data];
            return new Promise((resolve, reject) => {
              props
                .updateRow(newData, oldData)
                .then((res) => {
                  if (res) {
                    console.log('oldData:', oldData.tableData.id, oldData);
                    // const index = oldData.tableData.id;
                    const index = dataUpdate.findIndex((elem) => elem.id === oldData.tableData.id);
                    // console.log('onRowUpdate', index, oldData.tableData.id, dataUpdate);
                    if (index >= 0) {
                      dataUpdate[index] = newData;
                      setState((prevState) => ({ ...prevState, data: dataUpdate }));
                      resolve();
                    } else {
                      enqueueSnackbar('Einzelnachweis konnte nicht geändert werden.', { variant: 'error' });
                      reject();
                    }
                  } else {
                    reject();
                  }
                })
                .catch(() => reject('updatedRow failed.'));
            });
          },
          onRowDelete: (oldData) => {
            return new Promise((resolve, reject) => {
              const index = oldData.tableData.id;
              // const row = state.data.indexOf(oldData);
              const row = state.data.findIndex((elem) => elem.id === oldData.id);
              /*
               const row = state.data.findIndex((elem) =>
               Object.keys(elem)
               .map((key) => oldData[key] === elem[key])
               .every((x) => x === true)
               );
               */
              console.log('rowDelete:', row, index);
              props
                .deleteRow(oldData)
                .then((res) => {
                  if (res) {
                    console.debug(`table delete row ${row}`, oldData);
                    // noinspection JSCheckFunctionSignatures
                    setState((prevState) => {
                      const data = [...prevState.data];
                      data.splice(row, 1);
                      return { ...prevState, data };
                    });
                    resolve();
                  } else {
                    console.log(`ups: ${row}`, res);
                    reject();
                  }
                })
                .catch(() => reject());
            });
          },
        }}
        components={{
          // eslint-disable-next-line react/display-name
          Row: (props) => (
            <MTableBodyRow
              {...props}
              // see https://stackoverflow.com/questions/62316709/material-table-make-row-editable-on-click
              onDoubleClick={(e) => {
                console.log('onDoubleClick', props);
                // from SO: props.actions[1]().onClick(e, props.data);
                // but here are two extra actions, so ...
                // eslint-disable-next-line react/prop-types
                props?.actions[3]().onClick(e, props?.data);
              }}
            />
          ),
          /*
           EditField: (fieldProps) => {
           const {
           columnDef: { lookup },
           } = fieldProps;
           if (lookup) {
           console.log('fieldProps', fieldProps);
           return (
           <Autocomplete
           // options={Object.keys(fieldProps.columnDef?.lookup).map(key => obj[key])}
           options={Object.values(fieldProps?.columnDef?.lookup)}
           renderInput={(params) => {
           return <TextField {...params} variant="outlined" label={fieldProps?.value || 'n/a'} fullWidth />;
           }}
           onChange={(e) => fieldProps.onChange(e.target?.innerText)}
           />
           );
           } else {
           return <MTableEditField {...{ ...fieldProps, value: fieldProps?.value || '' }} />;
           }
           },
           */
        }}
        onSearchChange={(filters) => {
          console.log('onSearchChange', filters);
        }}
        onOrderChange={(filters) => {
          console.log('onOrderChange', filters);
        }}
        onFilterChange={(filters) => {
          const filterValues = filters.map((f) => ({
            field: f.column.field,
            value: f.value,
          }));
          // dispatch({type: 'setListFilters', payload: filterValues});
          console.log('onFilterChange', filterValues);
        }}
        // title={`Summe Korrekturen: ${currencyFormat(state.korrekturSum)} ; Geprüft: ${state.checkedCount} von ${state.data.length}`}
        title=<TableTitle infos={extraInfo} rows={state.data.length} />
      />
    </>
  );
};
ClaimPositionsTableRaw.propTypes = {
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  positionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  exportList: PropTypes.func.isRequired,
  actions: PropTypes.array,
  data: PropTypes.object,
};

const LoadingIndicator = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress color='secondary' />
    </div>
  );
};
// const EmptyMessage = () => <div>Keine Einzelnachweise gefunden.</div>;

const isLoadingConditionFn = (props) => props.isFetching;
const nullConditionFn = (props) => !props.positionsList;
// const isEmptyConditionFn = (props) => !props.positionsList.length;

const withConditionalRenderings = compose(
  withEither(isLoadingConditionFn, LoadingIndicator),
  withMaybe(nullConditionFn)
  // withEither(isEmptyConditionFn, EmptyMessage)
);

const ClaimPositionsTable = withConditionalRenderings(ClaimPositionsTableRaw);
// const ClaimPositionsTable = PureClaimPositionsTable;
export default ClaimPositionsTable;
