import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import gertrudeApi from '../helpers/axios-middleware';
import React from 'react';
import { useSnackbar } from 'notistack';

const DownloadFileButton = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const download = async (backendUrl, fileName, type, event) => {
    console.log('DownloadFileButton', backendUrl, type, event);
    try {
      const response = await gertrudeApi.get(backendUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]), {
        type: type || 'application/octet-stream',
      });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      link.setAttribute('rel', 'noopener');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      console.log('DownloadFileButton', response.status);
      return response.status === 200;
    } catch (e) {
      console.log('Aua.', e?.response);
      enqueueSnackbar('Fehler beim Download der PDF Datei: ' + (e.response.statusText || ''), { variant: 'error' });
      return false;
    }
  };

  return (
    <Button variant='contained' color='secondary' onClick={(e) => download(props.url, props.fileName, props.type, e)}>
      {props.label || props.fileName || 'Download'}
    </Button>
  );
};
DownloadFileButton.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  label: PropTypes.string,
};
DownloadFileButton.displayName = 'DownloadFileButton';

export default DownloadFileButton;
