import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';

const apiBaseUrl = window.location.protocol.concat('//', window.location.host);
// const apiBaseUrl = 'http://localhost:8000';
console.log(`Using ${apiBaseUrl} as base url.`);

/*
 export const querystring = (data) => {
 return Object.keys(data)
 .map((key) => {
 const value = data[key];
 if (value === undefined) {
 return '';
 }
 if (value === null) {
 return encodeURIComponent(key);
 }
 return encodeURIComponent(key) + '=' + encodeURIComponent(value);
 })
 .filter((x) => x.length > 0)
 .join('&');
 };
 */

const gertrudeApi = axios.create({
  baseURL: apiBaseUrl,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Cache-Control': 'no-cache,no-store,must-revalidate',
  },
  params: {},
});

// see https://github.com/axios/axios/issues/2315

const msalInstance = new PublicClientApplication(window.azureSPAmsalConfig);

// Add a request interceptor
const authInterceptReq = gertrudeApi.interceptors.request.use(
  async function (config) {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await msalInstance
      .acquireTokenSilent({
        ...window.azureSPAauthRequest,
        account: activeAccount || accounts[0],
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.logoutRedirect({
            account: activeAccount || accounts[0],
            onRedirectNavigate: () => {
              // Return false if you would like to stop navigation after local logout
              return false;
            },
          });
        } else {
          throw error;
        }
      });

    config.headers['Authorization'] = 'Bearer ' + response.accessToken;
    config.headers['Content-Type'] = 'application/json';

    // console.log('axios interceptors request', config);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
const authInterceptResp = gertrudeApi.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    // console.log('axios interceptors response', error?.response?.status);
    console.log('axios interceptors response', error);
    if (error.response.status === 401) {
      const activeAccount = msalInstance.getActiveAccount();
      const accounts = msalInstance.getAllAccounts();
      // logout user locally to trigger getting a new token
      msalInstance.logoutRedirect({
        account: activeAccount || accounts[0],
        // eslint-disable-next-line no-unused-vars
        onRedirectNavigate: (url) => {
          // Return false if you would like to stop navigation after local logout
          return false;
        },
      });
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export const ejectFaxservApi = () => {
  gertrudeApi.interceptors.request.eject(authInterceptReq);
  gertrudeApi.interceptors.request.eject(authInterceptResp);
};

export default gertrudeApi;
