// This must be the first line in src/index.js
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AppContextProvider } from './contexts/AppContext';
import './index.scss';
import * as serviceWorker from './serviceWorker';

require('typeface-roboto');

const pca = new PublicClientApplication(window.azureSPAmsalConfig);

ReactDOM.render(
  <>
    <MsalProvider instance={pca}>
      <AppContextProvider>
        <Router basename='/gui'>
          {/*<React.StrictMode>*/}
          <App />
          {/*</React.StrictMode>*/}
        </Router>
      </AppContextProvider>
    </MsalProvider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
