import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from './logo192.png';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { NavLink } from 'react-router-dom';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
import FaceIcon from '@material-ui/icons/Face';
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AppContext from './contexts/AppContext';
import { withRouter } from 'react-router';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import IconButton from '@material-ui/core/IconButton';
import { PublicClientApplication } from '@azure/msal-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.primary.contrastText,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  navBarProfilePart: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
}));

const pathTexts = {
  login: 'Login',
  logout: 'Login',
  profile: 'Profil',
  full_list: 'Gesamt Liste',
  list: 'Liste der Reklamationen',
  claim: 'Reklamation',
  '': 'Home',
};

/*
const NavPartLogin = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [context] = useContext(AppContext);
  // console.log('NavPartLogin render', context.isAuthenticated);
  /*
   useEffect(() => {
   const jwt_access_token = JSON.parse(sessionStorage.getItem('jwt_access_token'));
   console.log('Navbar useEffect', jwt_access_token);
   if (jwt_access_token === 'null' || jwt_access_token == null) {
   console.log('No login.');
   }
   return () => {}; // cleanup stuff
   }, [context.isAuthenticated]);
   * /
  return (
    <>
      {context.isAuthenticated === false ? (
        <NavLink to='/login'>
          <Button style={theme.palette.accent} className={classes.primary} startIcon={<LockOpenIcon />}>
            Login
          </Button>
        </NavLink>
      ) : (
        <NavLink to='/logout'>
          <Button style={theme.palette.accent} className={classes.primary} startIcon={<ExitToAppIcon />}>
            Logout
          </Button>
        </NavLink>
      )}
    </>
  );
};
*/

function signOutMsalClickHandler(instance) {
    const activeAccount = instance.getActiveAccount();
    const accounts = instance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const logoutRequest = {
        account: activeAccount || accounts[0],
        postLogoutRedirectUri: window.location.origin + "/loggedout"
    }
    instance.logoutRedirect(logoutRequest);
}

const NavPartTheme = () => {
  const [context, dispatch] = useContext(AppContext);
  const icon = !context.theme ? <Brightness7Icon /> : <Brightness3Icon />;
  return (
    <>
      {/*<Switch checked={context.theme} checkedIcon={<Brightness7Icon />} icon={<Brightness3Icon />} size="medium" />*/}
      <IconButton edge='end' color='inherit' aria-label='Theme change' onClick={() => dispatch({ type: 'theme' })}>
        {icon}
      </IconButton>
    </>
  );
};

const NavBarProfilePart = (props) => {
  const classes = useStyles();
  return (
    <Typography color='inherit' variant='h6' className={classes.navBarProfilePart}>
      {props.text && (
        <>
          {props.icon && props.icon}
          &nbsp;
          {props.text}
        </>
      )}
    </Typography>
  );
};
NavBarProfilePart.propTypes = {
  text: PropTypes.node,
  icon: PropTypes.node,
};

const ProtectedNav = (props) => {
  const { children } = props;
  const [context] = useContext(AppContext);
  if (context.isAuthenticated) {
    return children;
  } else return null;
};
ProtectedNav.propTypes = {
  children: PropTypes.node,
};

const NavBar = (props) => {
  const msalInstance = new PublicClientApplication(window.azureSPAmsalConfig);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [state] = useContext(AppContext);
  const paths = props.location.pathname.split('/');
  paths.shift();
  let text =
    pathTexts[Object.keys(pathTexts).find((element) => paths[0].startsWith(element))] ||
    pathTexts[props.location.pathname] ||
    props.location.pathname;
  /*
  if (paths.length > 1) {
    text += ' ' + paths[1];
  }
  */
  // console.group('nav bar');
  // console.log('text', text);
  // console.log('props', props);
  // console.log('state', state);
  // console.groupEnd();
  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <img src={logo} className='App-logo' alt='logo' />
          <Typography color='inherit' variant='h6' className={classes.title}>
            {props.pageTitle || text}
          </Typography>
          <div className={classes.title}>
            <NavBarProfilePart text={state.user} icon={<FaceIcon />} />
            <NavBarProfilePart text={state.group} icon={<GroupIcon />} />
          </div>
          <>
            <NavLink to='/full_list'>
              <Button style={theme.palette.accent} className={classes.primary} startIcon={<KeyboardIcon />}>
                Full List
              </Button>
            </NavLink>
            <NavLink to='/list'>
              <Button style={theme.palette.accent} className={classes.primary} startIcon={<FormatListBulletedIcon />}>
                List
              </Button>
            </NavLink>
            <NavLink to='/'>
              <Button style={theme.palette.accent} className={classes.primary} startIcon={<HomeIcon />}>
                Home
              </Button>
            </NavLink>
          </>
          <Button onClick={() => signOutMsalClickHandler(msalInstance)} style={theme.palette.accent} className={classes.primary} startIcon={<ExitToAppIcon />}>
            Logout
          </Button>
          <NavPartTheme />
        </Toolbar>
      </AppBar>
    </div>
  );
};
NavBar.propTypes = {
  pageTitle: PropTypes.string,
  location: PropTypes.object,
};

// export default withRouter(NavBar);
export default withRouter(NavBar);
