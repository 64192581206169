import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { pipe } from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { sanitizeScanList } from '../helpers/applyToProps';
import { useApi } from '../helpers/hooks';
import ScanListTable from './ScanListTable';

export const apiUrl = '/scans/list';
/**
 * A Table with all the Faxes reported by the REST API.
 *
 * @param props with the list of faxes in 'faxList'
 * @property changeFax a method called if a row is clicked, should display the given fax. defaults to an redirect to DataEntry.
 * @returns {JSX.Element}
 * @constructor
 */
const ScanList = (props) => {
  // const [scanList, setScanList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const redirectToClaimEdit = async (claim) => {
    if (claim === undefined || !claim) {
      console.log('redirectToClaimEdit: no id given', claim);
      return;
    }
    console.log('redirect to claim with id ', claim);
    history.push(`/claim/edit/${claim}`);
    return claim;
  };
  const claimChange = props.changeClaim ? props.changeClaim : redirectToClaimEdit;
  const apiResult = useApi(apiUrl, enqueueSnackbar, history);
  if (!apiResult || !Array.isArray(apiResult)) {
    console.debug('render ScanList has no result', apiResult);
    return (
      <div>
        <CircularProgress color='secondary' />
      </div>
    );
  }
  const scanList = pipe(sanitizeScanList)(apiResult);
  // return <SimpleFaxList data={data.faxList} isFetching={data.isFetching} />;
  return (
    <>
      {/*<pre>{JSON.stringify(props, null, 2)}</pre>*/}
      {/*<ScanListTable scanList={listInfo.scanList} isFetching={listInfo.isFetching} changeClaim={claimChange} />*/}
      <ScanListTable scanList={scanList} isFetching={fetch} changeClaim={claimChange} />
    </>
  );
};
ScanList.propTypes = {
  tableData: PropTypes.array,
  /**
   * a method to set the form data.
   */
  changeClaim: PropTypes.func,
};
ScanList.displayName = 'ScanList';

export default ScanList;
