import { isValid, parseJSON } from 'date-fns';

/**
 * recurse through an object and apply a a given function to all keys with
 * a given name .
 * @param  {Object} object  The object to change
 * @param  {String} key     a substring for the key to apply the function 'func' to
 * @param  {Function} func  a function taking one argument, the value of the key, returning a new value.
 * @return {Object}       Same object but with new contents for each property containing 'key'
 */
export function applyToProps(object, key, func) {
  let value;
  Object.keys(object).map(function (k) {
    if (k.includes(key)) {
      value = object[k];
      object[k] = func(value);
      // console.log(k, object[k], value);
    }
    if (object[k] && typeof object[k] === 'object') {
      object[k] = applyToProps(object[k], key, func);
    }
    return object;
  });
  return object;
}

/**
 * because JS assumes local timezone, and every DB assumes UTC.
 * FFFFFFUUUUUUU
 */
export function parseUTC(timeStr) {
  const jsonTime = parseJSON(timeStr + (timeStr.toString().endsWith('Z') ? '' : 'Z'));
  console.log('parseUTC json', timeStr, jsonTime);
  return jsonTime;
  // const utcTime = parse(
  //   timeStr + (timeStr.endsWith('Z') ? '' : 'Z'),
  //   "yyyy-MM-dd'T'HH:mm:ss.SSSSSSX",
  //   Date.now()
  // );
  // console.log('parseUTC SSSSSS', timeStr, utcTime);
  // return utcTime;
}

/**
 * fixes common issues in the data received from the backend REST API.
 * especially the dates which come as strings...
 * @param  {Object[]}    list  The json from the api is a list of objects.
 * @return {Object[]}    Same list but with better contents
 */
export function sanitizeScanList(list) {
  const datesList = ['createdAt', 'lastModified', 'mergedAt', 'rechnung_datum', 'brief_datum'];
  list = list.map((item) => {
    for (const f of datesList) {
      if (item[f]) {
        try {
          const d = parseJSON(item[f]);
          if (isValid(d)) {
            item[f] = d;
          } else {
            item[f] = parseUTC(item[f]);
          }
        } catch (e) {
          console.log('catch', e.message, item[f]);
          item[f] = '';
        }
      }
    }
    // item["createdAt"] = new Date(parseISO(item["createdAt"]).setHours(0, 0, 0, 0));
    // item['createdAt'] = parseJSON(item['createdAt']);
    // item['lastModified'] = parseJSON(item['lastModified']);
    return item;
  });
  return list;
  /*
  return list.map((fax) => {
    return applyToProps(fax, 'datum', (val) => {
      if (val === '' || val == null) {
        console.debug('invalid date', val);
        return new Date();
      }
      return parseJSON(val);
    });
  });
   */
}
