import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import gertrudeApi from '../helpers/axios-middleware';
import { fixLocalTimeZones, prettyErrorMsg } from '../helpers/backend-helpers';
import { diffObj } from '../helpers/functional';
import ClaimPositionsTableFromDB from './ClaimPositionsTableFromDB';

export const exportApiUrl = '/export/claims/with_query/';
// export const downloadPdfUrl = '/pdf/by_oid/';

/**
 * A Table with all the einzelnachweis merged with Linde Infos as reported by the REST API.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ClaimPositionsFromDB = () => {
  const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  // const classes = useStyles(theme);

  /**
   * fetches the xlsx file from the backend and make the browser download it.
   * uses the claim from the state to get the _id of the claim to query the backend API at '/export/claims/by_oid/'
   * see see https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
   * @param: event the click event on the export button, because reasons.
   */
  const exportList = async (searchParams) => {
    console.log('exportList from DB :', searchParams);
    try {
      const response = await gertrudeApi.post(`${exportApiUrl}`, searchParams, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]), {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const link = document.createElement('a');
      link.href = url;
      const dlDate = new Date().toLocaleTimeString('de-DE', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
      link.setAttribute('download', `Gesamtliste vom ${dlDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      console.log('exportList', response.status);
      enqueueSnackbar(`Download der Gesamtliste`, { variant: 'info' });
      return response.status === 200;
    } catch (e) {
      console.log('Aua.', e);
      enqueueSnackbar('Fehler beim Download der xlsx Datei: ' + (e.response.statusText || ''), { variant: 'error' });
      return false;
    }
  };

  const updateRowInDB = async (newData, oldData, lock) => {
    console.log('updateRowInDB: newData :', newData);
    console.log('updateRowInDB: oldData :', oldData);
    const diffData = diffObj(oldData, newData);
    // Blacklist some fields to avoid overwriting them...
    delete diffData.lifecycle;
    console.log('updateRowInDB diffData', diffData);
    if (diffData.startTime) {
      diffData.startTime = fixLocalTimeZones(diffData.startTime);
    }
    if (diffData.endTime) {
      diffData.endTime = fixLocalTimeZones(diffData.endTime);
    }
    const apiUrl = '/claims/pos/by_oid/';
    let row = -1;
    let o = { ...oldData };
    if (oldData.tableData) {
      row = oldData.tableData.id;
      delete o.tableData;
    }
    const data = {
      oid: oldData._id,
      arrayIndex: row,
      new_data: diffData,
      old_data: o,
      lock: lock,
    };
    try {
      const response = await gertrudeApi.patch(`${apiUrl}${data.oid}`, data);
      console.log('updateRowInDB', response);
      const ok = response.status === 200;
      // const ok = false; // DEBUG
      if (ok) {
        enqueueSnackbar('Daten gespeichert.', { variant: 'success' });
      } else {
        enqueueSnackbar('Daten konnten nicht gespeichert werden.', {
          variant: 'error',
        });
      }
      return ok;
    } catch (e) {
      console.log('Aua.', e, e?.response);
      enqueueSnackbar('Error saving the changes: ' + prettyErrorMsg(e), { variant: 'error' });
      return false;
    }
  };

  const addRowInDB = async (newData, lock) => {
    console.log('updateRowInDB newData :', newData);
    const apiUrl = '/claims/pos/by_oid/';
    const data = {
      oid: newData._id,
      new_data: newData,
      lock: lock,
    };
    try {
      const response = await gertrudeApi.post(`${apiUrl}${data.oid}`, data);
      console.log('addRowInDB', response);
      const ok = response.status === 200;
      if (ok) {
        console.log('added', data);
      }
      return ok;
    } catch (e) {
      console.log('Aua.', e);
      enqueueSnackbar('Error saving the changes: ' + (e?.response?.statusText || ''), { variant: 'error' });
      return false;
    }
  };

  const deleteRowInDB = async (oldData, lock) => {
    console.log('deleteRowInDB oldData :', oldData, lock);
    const apiUrl = '/claims/pos/delete/by_oid/';
    const req_data = {
      oid: oldData._id,
      old_data: { ...oldData },
      lock: lock,
    };
    // stupid material table stuff
    delete req_data.old_data.tableData;
    try {
      const response = await gertrudeApi.post(`${apiUrl}${req_data.oid}`, req_data);
      console.log('deleteRowInDB', response);
      const ok = response.status === 200;
      if (ok) {
        console.debug('claim deleted row');
      }
      return ok;
    } catch (e) {
      console.log('Aua.', e);
      enqueueSnackbar('Error saving the changes: ' + (e.response.statusText || ''), { variant: 'error' });
      return false;
    }
  };

  return (
    <>
      <ClaimPositionsTableFromDB updateRow={updateRowInDB} addRow={addRowInDB} deleteRow={deleteRowInDB} exportList={exportList} />
    </>
  );
};
ClaimPositionsFromDB.propTypes = {
  oid: PropTypes.string,
  // from router
  match: PropTypes.object,
  location: PropTypes.object,
  slim: PropTypes.bool,
};
ClaimPositionsFromDB.defaults = {
  slim: false,
};
ClaimPositionsFromDB.displayName = 'ClaimPositionsFromDB';

export default ClaimPositionsFromDB;
