import React, { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  date: Date(),
  isAuthenticated: false,
  jwt_access_token: null,
  jwt_refresh_token: null,
  user: null,
  setAuthenticated: () => {},
  theme: true,
  scanListFilters: [],
};

const reducer = (state, action) => {
  console.group('App context reducer');
  console.log('action', action);
  console.log('state', state.scanListFilters);
  console.groupEnd();
  switch (action.type) {
    case 'login':
      sessionStorage.setItem('user', JSON.stringify(action.payload.user));
      sessionStorage.setItem('jwt_access_token', JSON.stringify(action.payload['access_token']));
      sessionStorage.setItem('jwt_refresh_token', JSON.stringify(action.payload['refresh_token']));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user || null,
        jwt_access_token: action.payload['access_token'] || null,
        jwt_refresh_token: action.payload['refresh_token'] || null,
        date: Date(),
      };
    case 'logout':
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('jwt_access_token');
      sessionStorage.removeItem('jwt_refresh_token');
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        jwt_access_token: null,
        jwt_refresh_token: null,
        date: Date(),
      };
    case 'refresh':
      sessionStorage.setItem('jwt_access_token', JSON.stringify(action.payload['access_token']));
      sessionStorage.setItem('jwt_refresh_token', JSON.stringify(action.payload['refresh_token']));
      return {
        ...state,
        jwt_access_token: action.payload['access_token'] || null,
        jwt_refresh_token: action.payload['refresh_token'] || null,
      };
    case 'theme':
      console.log(`theme: ${state.theme}`, state);
      return {
        ...state,
        theme: !state.theme,
      };
    case 'setScanListFilters':
      sessionStorage.setItem('scanListFilters', JSON.stringify(action.payload));
      return {
        ...state,
        scanListFilters: action.payload,
      };
    default:
      return state;
  }
};

const AppContext = createContext(initialState);

const checkStorage = (initialState) => {
  console.log('checking storage');
  try {
    let state = { ...initialState };
    state.scanListFilters = JSON.parse(sessionStorage.getItem('faxListFilters'));
    const jwt_refresh_token = JSON.parse(sessionStorage.getItem('jwt_refresh_token'));
    const jwt_access_token = JSON.parse(sessionStorage.getItem('jwt_access_token'));
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user && jwt_refresh_token && jwt_access_token) {
      console.log(`checkStorage found an earlier login for ${user}`);
      console.log('checkStorage', jwt_access_token);
      if (jwt_access_token) {
        return {
          ...state,
          isAuthenticated: true,
          user: user,
          jwt_access_token: jwt_access_token,
          jwt_refresh_token: jwt_refresh_token,
        };
      } else {
        console.log('No valid login in sessionStorage.');
        return state;
      }
    } else {
      console.log('No user in sessionStorage.');
      return state;
    }
  } catch (error) {
    console.error('local Storage not available.', error);
    return initialState;
  }
};

/*
 const jwt_refresh = async (logout) => {
 console.log('jwt_refresh...');
 const jwt_refresh_token = JSON.parse(sessionStorage.getItem('jwt_refresh_token'));
 const jwt_access_token = JSON.parse(sessionStorage.getItem('jwt_access_token'));
 // const user = JSON.parse(sessionStorage.getItem('user'));
 if (jwt_refresh_token && jwt_access_token) {
 const qs = querystring({
 grant_type: 'refresh_token',
 refresh_token: jwt_refresh_token,
 access_token: jwt_access_token,
 client_id: authRealmName,
 });
 try {
 const response = await axios.post(`${authBaseUrl}${authLoginUrl}`, qs, {
 headers: {
 'Content-Type': 'application/x-www-form-urlencoded',
 },
 });
 if (response.status === 200) {
 console.log(`refreshed JWT access token`, response);
 sessionStorage.setItem('jwt_access_token', JSON.stringify(response.data['access_token']));
 sessionStorage.setItem('jwt_refresh_token', JSON.stringify(response.data['refresh_token']));
 return [response.data['access_token'], response.data['refresh_token']];
 } else {
 console.log(`could NOT refresh JWT access_token. (if)`, response);
 // clear the access token.
 // sessionStorage.setItem('jwt_access_token', JSON.stringify(null));
 sessionStorage.removeItem('jwt_access_token');
 return [null, jwt_refresh_token];
 }
 } catch (error) {
 // sessionStorage.setItem('jwt_access_token', JSON.stringify(null));
 sessionStorage.removeItem('jwt_access_token');
 console.warn(`could NOT refresh JWT access_token (catch).`, error.response);
 logout();
 return [null, jwt_refresh_token];
 }
 } else {
 console.log('not logged in.');
 sessionStorage.setItem('jwt_access_token', JSON.stringify(null));
 return [null, null];
 }
 };
 */

const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState, checkStorage);
  const logout = useCallback(() => {
    dispatch({ type: 'logout' });
    sessionStorage.removeItem('jwt_access_token');
    console.log('context logout');
  }, []);
  state['logout'] = logout;
  useEffect(() => {
    console.log('init jwt_refresh.');
    const storageChangeHandler = (event) => {
      console.log('storageChangeHandler', event['key']);
      if (event['key'] === 'jwt_refresh_token') {
        state['jwt_refresh_token'] = event['newValue'];
      }
      if (event['key'] === 'jwt_access_token') {
        state['jwt_access_token'] = event['newValue'];
        console.log('storageChangeHandler jwt_access_token', event);
        if (event['newValue'] == null || event['newValue'] === 'null') {
          console.log('user was logged out.', dispatch);
          state['logout']({ type: 'logout' });
        }
      }
    };
    const intervalId = undefined;
    // const intervalId = setInterval(jwt_refresh, 2 * 60 * 1000, logout);
    window.addEventListener('storage', storageChangeHandler);
    return () => {
      // cleanup stuff
      console.log('cleanup jwt_refresh');
      window.removeEventListener('storage', storageChangeHandler);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [state.setAuthenticated, state, logout]);
  return <AppContext.Provider value={[state, dispatch]}>{props.children}</AppContext.Provider>;
};
AppContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AppContext;
export { AppContextProvider };
