import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import DateFnsUtils from '@date-io/date-fns';
import '@fontsource/roboto';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import './App.scss';
import ClaimPositions from './components/ClaimPositions';
import ClaimPositionsFromDB from './components/ClaimPositionsFromDB';
import Home from './components/Home';
import ScanList from './components/ScanList';
import AppContext from './contexts/AppContext';
import Logout from './Logout';
import NavBar from './Navbar';
import { darkTheme, lightTheme } from './theme';

// import useMediaQuery from '@material-ui/core/useMediaQuery';

const PAGE_TITLE = 'Reklamationen';

const useStyles = makeStyles((theme) => ({
  NavBar: {
    marginBottom: theme.spacing(1),
  },
  App: {
    '& flexGrow': 1,
    '& paper': {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    '& imageView': {
      minWidth: '300px',
      minHeight: '400px',
    },
  },
}));

const App = () => {
  const [context] = useContext(AppContext);
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  document.title = PAGE_TITLE;
  const classes = useStyles();
  const location = useLocation();
  /*
   useEffect(() => {
   console.log('init app.');
   return () => {
   console.log('cleanup app');
   }; // cleanup stuff
   }, []);
   */
  console.log('App render:', location.pathname);
  const currentTheme = context.theme ? darkTheme : lightTheme;

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={window.azureSPAauthRequest}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={currentTheme}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
              autoHideDuration: 5000,
            }}
          >
            <Container maxWidth='xl' className={classes.root}>
              <div className={classes.NavBar}>
                <NavBar />
              </div>
              <Switch>
                <Route path='/full_list'>
                  <ClaimPositionsFromDB />
                </Route>
                <Route path='/list'>
                  <ScanList />
                </Route>
                <Route path='/claim/edit/:oid' component={ClaimPositions} />
                <Route path='/logout'>
                  <Logout />
                </Route>
                {/* catch all route, as each path starts with a "/" */}
                <Route path='/'>
                  <Home />
                </Route>
              </Switch>
            </Container>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </MsalAuthenticationTemplate>
  );
};

export default App;
