import { DevTool } from '@hookform/devtools';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { isValid as isValidDate, parseJSON } from 'date-fns';
import parseISO from 'date-fns/fp/parseISO';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { withEither, withMaybe } from '../helpers/functional';
import { getNavigatorLanguage } from '../helpers/material-table-helpers';

const formatDateTime = new Intl.DateTimeFormat(getNavigatorLanguage(), {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
  timeZone: 'Europe/Berlin',
});

const now = new Date();
// const tenYearsBeforeNow = new Date().setFullYear(now.getFullYear() - 10);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dateField: {
    width: '10rem',
    marginRight: theme.spacing(4),
    // border: '1px solid pink',
  },
  textField: {
    // marginLeft: theme.spacing(4),
    flexShrink: 1,
    marginRight: theme.spacing(4),
    // border: '1px solid purple',
  },
  // does not work here, so put it in the App.sccs file.
  numberField: {
    border: '1px solid purple',
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
    },
  },
  dense: { marginTop: theme.spacing(1) },
  menu: { width: 200 },
  button: { margin: theme.spacing(1) },
  extendedIcon: { marginRight: theme.spacing(1) },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const ClaimErrorList = (props) => {
  const allErrors = {
    rechnungsliste: (
      <Typography color='error' display='inline'>
        Rechnungsliste
      </Typography>
    ),
    rechnung_datum: 'Rechnungs-Datum',
    rechnung_betrag: 'Betrag',
    betrag_korrektur: 'Korrektur',
    korrektur_differenz: 'Korrektur Differenz',
    betrag_zahlbetrag: 'Zahlbetrag',
    zeichen: 'Zeichen',
    brief_datum: 'Absende Datum',
    einzelnachweis: 'Einzelnachweise',
  };
  if (props.errorList) {
    const readableList = props.errorList.map((err) => allErrors[err]).filter(Boolean);
    console.debug('readableList', readableList, allErrors, props.errorList);
    // return <>{readableList.join(', ')}</>;
    return (
      <>
        {readableList.map((item, index) => {
          return (
            <span key={index}>
              {index > 0 ? ', ' : ''}
              {item}{' '}
            </span>
          );
        })}
      </>
    );
  } else {
    return <span>Keine</span>;
  }
};
ClaimErrorList.propTypes = {
  errorList: PropTypes.arrayOf(PropTypes.string),
};
ClaimErrorList.displayName = 'ClaimErrorList';

/*
 function TriggerFormInitially({ form }) {
 const { trigger } = form;
 useEffect(() => {
 console.debug('call trigger');
 trigger();
 }, [trigger]);
 return null;
 }
 */

const NicerDatePickerField = (props) => {
  const { value, control, name, label, ...rest } = props;
  console.log(`NicerDatePickerField ${value}`, props);
  // console.log('NicerDatePickerField errors', errors);
  // To enable RHF to focus on the picker on error - https://react-hook-form.com/api/#Controller
  // https://stackoverflow.com/questions/59901775/ref-object-is-possibly-undefined-typescript-react#comment105994469_59901896
  // const inputRef = useRef();
  const inputFormat = 'dd.MM.yyyy';
  // const handleChange = React.useCallback(
  //   (date) => {
  //     const correctDate = jsDateToLocalISO8601DateString(date);
  //     console.log('handleChange', date, correctDate);
  //     onChange({ target: { value: date ? correctDate : null } });
  //   },
  //   [onChange]
  // );
  return (
    <Controller
      {...rest}
      render={({ field, ref }) => (
        <KeyboardDatePicker
          {...field}
          label={label}
          // renderInput={(props) => <TextField label={name} helperText="Datum" />}
          // inputFormat='dd.MM.yyyy'
          autoOk
          disableFuture
          disableToolbar
          variant='inline'
          // This will cause the value of received date to have an ISO date and a formatted date in an array,
          // so it's recommended you use watch(name)[0] to extract the ISO date and [1] for the formatted date.
          format={inputFormat}
          ref={ref}
        />
      )}
      control={control}
      name={name}
      // error={!!errors[name]}
      // helperText={!errors[name] ? 'Datum der Reklamation' : errors[name]?.message}
      rules={{
        required: 'Bitte ein Datum setzen',
        // Because minutes not multiple by `minutesStep` (happens if the user types them in) does not trigger an error
        // (https://github.com/mui-org/material-ui-pickers/issues/1826), we need to manually check for that.
        validate: {
          // valid: (d) => (d instanceof Date && !isNaN(d.getTime())) || 'Please enter a valid date',
          // future: (d) => d.getTime() < Date.now() || 'Date should be in the past',
        },
      }}
      // Set focus on this field on error
      // onFocus={() => inputRef.current.focus()}
      // Set the seconds to zero - https://github.com/mui-org/material-ui-pickers/issues/1825
      // onChange={(date) => date && new Date(date.setSeconds(0))}
      // onChange={(date) => handleChange(date)}
    />
  );
};
NicerDatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object.isRequired,
};
NicerDatePickerField.defaulValue = {
  value: '',
};
NicerDatePickerField.displayName = 'NicerDatePickerField';

const SimpleDatePickerField = (props) => {
  const classes = useStyles();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  /*
   const handleChange = React.useCallback(
   (date) => {
   const correctDate = jsDateToLocalISO8601DateString(date);
   console.log('handleChange', date, correctDate);
   onChange({ target: { value: date ? correctDate : null } });
   },
   [onChange]
   );
   */
  return (
    <TextField
      type='date'
      InputLabelProps={{
        shrink: true,
      }}
      id={props.name}
      name={props.name}
      label={props.label || props.name}
      defaultValue={props.name}
      {...register(props.name, {
        required: true,
        validate: {
          past: (value) => parseISO(value) < now || 'should be in the past',
          // notSoFar: (value) => parseJSON(value) > tenYearsBeforeNow || 'should not be that long ago',
        },
      })}
      className={classes.textField}
      helperText={!errors[props.name] ? null : errors[props.name]?.message}
    />
  );
};
SimpleDatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};
SimpleDatePickerField.displayName = 'DatePickerField';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ClaimInfosRaw = (props) => {
  const classes = useStyles();
  const currencySymbol = '€';
  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    control,
    trigger,
    methods,
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      zeichen: props.info?.zeichen,
      brief_datum: props.info?.brief_datum || '',
      // rechnung_datum: props.info?.rechnung_datum,
      rechnung_betrag: parseFloat(props.info?.rechnung_betrag),
      ik_nr: parseInt(props.info?.ik_nr),
    },
  });

  const onSubmit = (values) => {
    console.log('ClaimInfo submit:', values);
    // datepicker give now a string with time set to midnight UTC.
    // values.brief_datum = new Date(values.brief_datum.setHours(12, 0, 0, 0));
    props.updateClaim(values);
  };

  useEffect(() => {
    console.log('call trigger');
    trigger().then((r) => console.log('trigger:', r));
  }, [trigger]);

  // console.log('errors: ', errors);

  const lm = parseJSON(props.info['lastModified']);
  const parsedDate = parseJSON(props.info.brief_datum);
  if (isValidDate(parsedDate)) props.info.brief_datum = parsedDate;
  else props.info.brief_datum = '';
  return (
    <>
      <DevTool control={control} placement={'top-right'} />
      {/*<pre>{JSON.stringify(props.info, null, 2)}</pre>*/}
      <Box m={1} p={0} flexGrow={1}>
        <Typography variant='h3' gutterBottom>
          Rechnungsliste {props.info['rechnungsliste']}
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='row' flexWrap='wrap' justifyContent='flex-start'>
              {/*<SimpleDatePickerField name={'brief_datum'} label={'Brief Datum'} />*/}
              <Box className={classes.dateField} flexShrink={1}>
                <NicerDatePickerField
                  name={'brief_datum'}
                  style={{ marginRight: '1rem', backgroundColor: 'blue' }}
                  value={props.info.brief_datum}
                  label={'Brief Datum'}
                  rules={{ required: 'Benötigt' }}
                  control={control}
                />
              </Box>
              <Controller
                name='rechnung_betrag'
                rules={{
                  required: 'Benötigt',
                  min: { value: 0, message: 'Nur den absolut Betrag' },
                  max: { value: 1000000, message: 'Zu viel' },
                }}
                defaultValue={props.info.rechnung_betrag}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { _invalid, _isTouched, _isDirty, error },
                  _formState,
                }) => (
                  <NumberFormat
                    name={name}
                    label='Gesamt Betrag'
                    suffix={currencySymbol}
                    thousandSeparator={'\u2009'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    defaultValue={parseFloat(value)}
                    onValueChange={(value) => onChange(value.floatValue)}
                    onBlur={onBlur}
                    customInput={TextField}
                    className={classes.textField}
                    error={!!error}
                    helperText={!error ? 'Der Gesamtbetrag' : error.message}
                    inputRef={ref}
                    inputProps={{ size: 12 }}
                  />
                )}
                control={control}
              />

              <Controller
                name='zeichen'
                rules={{ required: 'Benötigt' }}
                defaultValue={props.info.zeichen}
                render={({ field: { onChange, value, name, ref }, fieldState: { _invalid, _isTouched, _isDirty, error }, _formState }) => (
                  <TextField
                    name={name}
                    id={name}
                    label='Zeichen'
                    className={classes.textField}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={!error ? 'Das von der KK' : error.message}
                    // helperText={!errors.zeichen ? 'Das von der KK' : errorsText(errors.zeichen)}
                    inputRef={ref}
                    inputProps={{ size: 17 }}
                  />
                )}
                control={control}
              />
              <Controller
                name='kk_name'
                rules={{
                  required: 'Benötigt',
                }}
                defaultValue={props.info.kk_name ?? ''}
                render={({ field: { onChange, value, name, ref }, fieldState: { _invalid, _isTouched, _isDirty, error }, _formState }) => (
                  <TextField
                    name={name}
                    id={name}
                    label='Kranken Kasse'
                    className={classes.textField}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={!error ? 'Name der KK' : error.message}
                    inputRef={ref}
                    inputProps={{ size: 24 }}
                  />
                )}
                control={control}
              />

              <Controller
                name='ik_nr'
                rules={{ required: 'Benötigt', pattern: /^[0-9]+$/i }}
                defaultValue={props.info.ik_nr}
                render={({ field: { onChange, value, name, ref }, fieldState: { _invalid, _isTouched, _isDirty, error }, _formState }) => (
                  <TextField
                    name={name}
                    id={name}
                    label='IK Nummer'
                    className={classes.textField}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={!error ? 'Die von der der KK' : error.message}
                    inputRef={ref}
                    inputProps={{ size: 10 }}
                  />
                )}
                control={control}
              />

              <Button
                type='submit'
                className={classes.button}
                disabled={!isValid || isSubmitting}
                size={'large'}
                variant='contained'
                color='secondary'
                aria-label='Abschicken'
              >
                Submit
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
      <Box my={0} mx={1} p={1}>
        <ul>
          <li key='Krankenkasse'>
            Krankenkasse ist {props.info['kk_name'] || props.info['parser'] || 'unbekannt'}, IK Nr. {props.info['ik_nr']}
          </li>
          {/*<li key='Zeichen'>Zeichen ist {props.info?.zeichen}</li>*/}
          <li key='Datei'>
            Eingelesen aus Datei {props.info['filename']} mit ID {props.info['import_id']}
          </li>
          <li key='Seiten'>mit {props.info['images_length']} Seiten</li>
          <li key='Positionen'>mit {props.info['einzelnachweis_length']} (erkannten) Positionen</li>
          <li key='createdAt'>am {formatDateTime.format(parseJSON(props.info['createdAt']))}</li>
          {props.info['mergedAt'] ? (
            <li key='verknüpft'>
              verknüpft mit Import {props.info['mergedFrom']} am {formatDateTime.format(parseJSON(props.info['mergedAt']))}
            </li>
          ) : null}
          <li key='Änderung'>letzte Änderung {formatDateTime.format(lm)}</li>
          <li key='Import'>
            Import Fehler: <ClaimErrorList errorList={props.info['fails']} />
          </li>
        </ul>
      </Box>
    </>
  );
};
ClaimInfosRaw.propTypes = {
  info: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
ClaimInfosRaw.displayName = 'ClaimInfosRaw';

const LoadingIndicator = () => (
  <div>
    <CircularProgress color='secondary' />
  </div>
);
const EmptyMessage = () => <div>Keine Informationen gefunden.</div>;

const isLoadingConditionFn = (props) => props.isFetching;
const nullConditionFn = (props) => !props.info;
const isEmptyConditionFn = (props) => Object.keys(props.info).length === 0;

const withConditionalRenderings = compose(
  withEither(isLoadingConditionFn, LoadingIndicator),
  withMaybe(nullConditionFn),
  withEither(isEmptyConditionFn, EmptyMessage)
);

const ClaimInfo = withConditionalRenderings(ClaimInfosRaw);

export default ClaimInfo;
