import MaterialTable, { MTableBodyRow, MTableEditRow } from '@material-table/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import WarningIcon from '@material-ui/icons/Warning';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { pipe } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import gertrudeApi from '../helpers/axios-middleware';
import { addId, deleteLock, getLock, sanitizeList } from '../helpers/backend-helpers';
import { EditGrund, errorReasons, statusList } from '../helpers/ClaimPositionHelpers';
import { useSessionState } from '../helpers/hooks';
import {
  ampel_lookup,
  ampelBackgroundColor,
  currencySetting,
  errorStyle,
  getNavigatorLanguage,
  localization,
  MaterialTableAmpelRender,
  tableIcons,
} from '../helpers/material-table-helpers';

const apiUrl = '/claims/all/';

const navLang = getNavigatorLanguage();
const intlDateTimeOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};
const intlDateTimeFormat = new Intl.DateTimeFormat(navLang, intlDateTimeOptions);

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
    },
    normRow: {
      backgroundColor: theme.palette.secondary.main,
    },
    highRow: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  loading: {
    // display: 'flex',
    display: 'inline-block',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '200px',
    height: '100px',
    margin: 'auto',
    backgroundColor: theme.palette.secondary.main,
  },
}));

const clusterClaims = Object.fromEntries(
  [
    null,
    'Abgelehnt',
    'Abrechnungsfrist',
    'Doppelberechnung',
    'DTA / Ebilling / Datensatz falsch',
    'Erst RE fehlt bei Kasse',
    'Erstlieferung war später',
    'falschen Vertrag / Versorgung ausgewählt',
    'Falscher Debitor',
    'Genehmigung fehlt / falsch',
    'Genehmigungsnummer fehlt / falsch',
    'Geräte wurden bereits abgeholt',
    'GOX Mehrbedarf',
    'Hinweis zur Erst VO fehlt',
    'HMV-Nr. / Himi-Nr. / Produktbesonderheit fehlt /falsch',
    'IK falsch',
    'in Pauschale enthalten',
    'Kassenspez. Anlage fehlt',
    'keine Lieferung / Krankenhausaufenthalt',
    'keine Mitgliedschaft / KK Wechsel',
    'Klinikanschrift falsch',
    'Kopie Rezept fehlt / falsch',
    'LEGS / LEB / ACTK fehlt / falsch',
    'Leistung vor Austellung der Verordnung',
    'Leistungskennzeichen / Himikennzeichen fehlt / falsch',
    'Lieferschein fehlt / falsch',
    'Menge falsch',
    'MIP / Poolbuchung fehlt',
    'Original Rezept fehlt / falsch',
    'Pat verstorben',
    'Preis falsch',
    'pro Genehmigung eine Rechnung',
    'Rechnung ohne Anlagen VS',
    'Rezept falsch ausgestellt / Rezeptdaten falsch',
    'Rückholung liegt vor Vetrag nicht abgeschlossen',
    'Therapieabbruch',
    'Umversorgung auf andere Therapie',
    'Unterschrift auf LS fehlt',
    'Versicherungsnummer falsch',
    'Versorgung durch anderen Anbieter',
    'Versorgungszeitraum falsch',
    'Zuzahlung falsch',
  ].map((x) => [x, x])
);

/*
 const EditMultiSelect = (props) => {
 const makeMenuItem = (value, label, key) => (
 <MenuItem key={key} value={value}>
 {label}
 </MenuItem>
 );
 const entries = Object.entries(props.columnDef.lookup).map(([k, v], i) => makeMenuItem(k, v, i));
 console.log('EditMultiSelect render', entries, props.value);
 if (!props.value) props.onChange([]);
 else if (props.value && !(props.value instanceof Array)) props.onChange([props.value]);
 return (
 <>
 <Select
 id={props.columnDef?.field + 'Edit'}
 multiple
 value={props.value}
 // onChange={(e) => console.log('select: ', e, props.value)}
 onChange={(e) => props.onChange(e.target.value)}
 input={<Input name={props.columnDef?.field} />}
 >
 {entries}
 </Select>
 </>
 );
 };
 EditMultiSelect.propTypes = {
 value: PropTypes.array.isRequired,
 onChange: PropTypes.func.isRequired,
 columnDef: PropTypes.shape({
 lookup: PropTypes.object.isRequired,
 field: PropTypes.string.isRequired,
 }),
 };
 EditMultiSelect.defaultProps = {
 value: ['None'],
 };
 EditMultiSelect.displayName = 'EditMultiSelect';
 */

const MyTableEditRow = (props) => {
  console.log('MyTableEditRow', props);
  const { onEdit, offEdit, ...rest } = props;
  useEffect(() => {
    console.log('MyTableEditRow useEffect');
    if (onEdit && typeof onEdit === 'function') onEdit(props.data);
    return () => {
      console.log('MyTableEditRow cleanup');
      if (offEdit && typeof offEdit === 'function') offEdit(props.data);
    };
  }, [onEdit, offEdit, props.data]);
  return <MTableEditRow {...rest} />;
};
MyTableEditRow.propTypes = {
  onEdit: PropTypes.func.isRequired,
  offEdit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const ClaimPositionsTableRaw = (props) => {
  const tableRef = React.createRef();
  const theme = useTheme();
  const classes = useStyles();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [lock, setLock] = useState({});

  const columns = [
    {
      title: 'Geprüft',
      field: 'checked',
      editable: 'always',
      type: 'boolean',
      render: function renderChecked(rowData) {
        const checked = rowData.checked;
        if (checked === undefined) return <PanoramaFishEyeIcon color='disabled' />;
        if (checked === true) return <CheckCircleIcon style={{ color: green[500] }} />;
        if (checked === false) return <ErrorIcon style={{ color: red[500] }} />;
        return <WarningIcon color='secondary' />;
      },
      cellStyle: {
        maxWidth: '3rem',
      },
      headerStyle: {
        maxWidth: '5rem',
      },
    },
    {
      title: 'Status',
      field: 'status',
      editable: 'always',
      filtering: true,
      lookup: statusList,
    },
    {
      title: 'Ampel',
      field: 'ampel',
      editable: 'never',
      filtering: true,
      lookup: ampel_lookup,
      // eslint-disable-next-line react/display-name
      render: (rowData) => <MaterialTableAmpelRender value={rowData || null} col={'ampel'} />,
      cellStyle: (value) => ({
        // opacity: 0.66,
        background: `linear-gradient(to right, ${ampelBackgroundColor(value)}, 22%, ${theme.palette.background.paper} )`,
      }),
    },
    {
      title: 'Tage seit Eingang',
      field: 'days_since',
      type: 'numeric',
    },
    {
      title: 'Krankenkasse',
      field: 'kk_name',
      editable: 'never',
      // lookup: kkNames,
      filtering: true,
    },
    {
      title: 'Rechnungsliste',
      field: 'rechnungsliste',
      editable: 'never',
      filtering: true,
      cellStyle: (value) => (!value ? errorStyle(theme) : null),
    },
    {
      title: 'Fehler erzeugt',
      field: 'error_from',
      editable: 'always',
      filtering: true,
      lookup: errorReasons,
    },
    {
      title: 'Grund',
      field: 'clusterClaim',
      editable: 'always',
      filtering: true,
      lookup: clusterClaims,
    },
    {
      title: 'Beleg',
      field: 'beleg',
      editable: 'always',
      grouping: false,
    },
    {
      title: 'Name',
      field: 'name',
      editable: 'always',
      grouping: true,
    },
    {
      title: 'Rechnung Nr',
      field: 'rechnung_nr',
      editable: 'always',
    },
    {
      title: 'Korrektur',
      field: 'korrektur',
      // filtering: false,
      editable: 'always',
      type: 'currency',
      currencySetting: currencySetting,
    },
    {
      title: 'Erste Bearbeitung',
      field: 'startTime',
      type: 'date',
      dateSetting: { locale: navLang },
      editable: 'onUpdate',
      // eslint-disable-next-line react/display-name
      // editComponent: (props) => <MTDateRender {...props} />,
    },
    {
      title: 'finale Bearbeitung',
      field: 'endTime',
      type: 'date',
      dateSetting: { locale: navLang },
      editable: 'onUpdate',
    },
    {
      title: 'last Modified',
      field: 'lastModified',
      type: 'datetime',
      dateSetting: { locale: navLang },
      editable: 'never',
      render: (rowData) =>
        rowData.lastModified instanceof Date ? intlDateTimeFormat.format(rowData.lastModified) : rowData.lastModified,
    },
    {
      title: 'Grund (Text)',
      field: 'grund',
      editable: 'onAdd',
      cellStyle: {
        width: '25rem',
        minWidth: '21rem',
      },
      editComponent: EditGrund,
    },
    {
      title: 'Bemerkung',
      field: 'notes',
      editable: 'always',
      filtering: true,
    },
    /*
     {
     title: 'Geburtsdatum',
     field: 'geburtsdatum',
     type: 'date',
     editable: 'always',
     },
     {
     title: 'KV Nummer',
     field: 'kv_nummer',
     editable: 'always',
     },
     {
     title: 'Pic-Nr',
     field: 'picnr',
     editable: 'always',
     },
     // DAK zeugs, eigentlich überflüssig.
     {
     title: 'Art.-Pos.-Nr.',
     field: 'artPosNr',
     editable: 'always',
     },
     {
     title: 'Pos.',
     field: 'pos',
     editable: 'always',
     type: 'numeric',
     cellStyle: {
     width: '2rem',
     maxWidth: '3rem',
     },
     headerStyle: {
     width: '2rem',
     maxWidth: '2rem',
     },
     },
     */
  ];
  const pageSizeOptions = [5, 10, 20, 50, 100, 200, 500, 1000];
  const [state, setState] = useState({
    columns: [...columns],
    searchParams: [],
    page: 0,
    pageSize: pageSizeOptions[1],
  });
  const ampelCol = columns.map((e) => e.field).indexOf('ampel');
  const [sortBy, setSortBy] = useSessionState('scanListOrder', { orderBy: ampelCol, orderDirection: 'asc' }); // eslint-disable-line no-unused-vars

  /*
   const updateCheckeClaimsCount = useCallback(async () => {
   const sum = state.data.reduce((a, b) => a + (b['checked'] === true ? 1 : 0), 0);
   console.debug(`updateCheckeClaimsCount: ${sum}, pos List:`, state.data);
   return sum;
   }, [state.data]);
   */

  const actionPosChecked = async (rowData) => {
    console.log('actionPosChecked', rowData);
    try {
      const lock_data = await getLock(gertrudeApi, rowData._id);
      // eslint-disable-next-line no-unused-vars
      const { _tableData, ...cleanData } = rowData;
      // toggle checked flag for DB.
      const newData = { ...cleanData };
      newData['checked'] = !newData['checked'];
      console.log(tableRef.current);
      // const scopedRef = tableRef.current;
      props.updateRow(newData, cleanData, lock_data).then((res) => {
        console.log('onRowUpdate then', res);
        deleteLock(gertrudeApi, lock_data).then((r) => {
          console.log('deleteLock:', r);
        });
        if (res) {
          // enqueueSnackbar(`Status auf ${newData['checked'] ? 'geprüft' : 'ungeprüft'} gesetzt`, { variant: 'info' });
          console.log(`Updated row ${rowData.tableData.id}`);
          // hack to update the table after a single row changes...
          tableRef.current.onQueryChange();
        } else {
          console.log(`Update status failure for ${rowData.tableData.id}`);
        }
      });
    } catch (e) {
      if (e.response && e.response.status === 400) {
        enqueueSnackbar('Reklamation kann nicht gesperrt werden.', {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Netzwerk Fehler.', { variant: 'error' });
      }
    }
  };

  const getSearchParams = (filters) => {
    console.log('filters', filters);
    // if (!filters[0]) return {};
    const params = filters.map((f) => {
      let p = {};
      p.sort = f?.column?.tableData?.groupSort;
      p.field = f.column.field;
      if (f.column.type || f.column.lookup) p.type = f.column.lookup ? 'lookup' : f.column.type;
      p.value = f.value;
      p.operator = f.operator;
      return p;
    });
    console.log('params', params);
    return params;
  };

  const fetchData = (query) => {
    console.log('data query:', query);
    const searchParams = getSearchParams(query.filters);
    setState((prevState) => ({ ...prevState, searchParams }));
    const sortCol = query?.orderBy?.field ? query.orderBy.field : '';
    const sortDir = query?.orderDirection || '';
    console.log('searchParams', searchParams);
    console.log('sort', sortCol, sortDir);
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}?&page=${query.page + 1}&page_size=${query.pageSize}&sort_by=${sortCol}&sort_direction=${sortDir}`;
      const apiPromise = searchParams.length > 0 ? gertrudeApi.post(url, searchParams) : gertrudeApi.get(url);
      apiPromise
        .then((result) => {
          console.log('data res:', result?.data?.data);
          const positionsList = pipe(addId, sanitizeList)(result?.data?.data);
          console.log('positionsList:', positionsList);
          resolve({
            data: positionsList,
            page: query.page,
            totalCount: result.data.totalCount,
          });
        })
        .catch((e) => {
          if (e.response && (e.response.status === 401 || e.response.status === 403)) {
            enqueueSnackbar('Not logged in?', { variant: 'warning' });
            history.push('/login');
          } else {
            console.log('Aua', e);
            reject('Netzwerkfehler. Nicht angemeldet? ');
          }
        });
    });
  };

  const removeLock = () => {
    if (Object.keys(lock).length > 0) {
      deleteLock(gertrudeApi, lock).then((r) => {
        console.log('Row unlock: ', r);
        setLock({});
      });
    }
  };
  const applyLock = (rowData) => {
    if (!rowData) return;
    console.log('applyLock  _id', rowData?._id);
    console.log('applyLock lock', lock);
    // check if there a lock already?
    if (Object.keys(lock).length === 0) {
      getLock(gertrudeApi, rowData._id)
        .then((lock_data) => {
          console.log('applyLock got lock', lock_data);
          setLock(lock_data);
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            enqueueSnackbar('Reklamation kann nicht gesperrt werden.', {
              variant: 'warning',
            });
          } else {
            enqueueSnackbar('Netzwerk Fehler.', { variant: 'error' });
          }
          console.log('applyLock effect catch:', e?.status);
          console.log(e);
        });
    }
  };

  console.log('render state:', state);
  return (
    <>
      <MaterialTable
        title='Gesamt-Liste'
        tableRef={tableRef}
        icons={tableIcons}
        className={classes.root}
        onRowClick={(evt, selectedRow) => {
          console.log('click on row', selectedRow);
          // setSelectedRow(selectedRow.tableData.id);
        }}
        onRowSelected={(selectedRow) => {
          console.log('onRowSelected', selectedRow);
        }}
        options={{
          paging: true,
          pageSize: state.pageSize,
          pageSizeOptions: pageSizeOptions,
          emptyRowsWhenPaging: false,
          paginationPosition: 'top',
          padding: 'dense',
          showTitle: true,
          toolbar: true,
          grouping: true,
          filtering: true,
          search: false,
          addRowPosition: 'first',
          rowStyle: {
            // fontSize: '1rem',
            verticalAlign: 'top',
          },
          headerStyle: { position: 'sticky', top: '0px' },
          maxBodyHeight: 'calc(100vh - 72px - 53px - 64px - 56px)',
        }}
        localization={localization}
        debounceInterval={500}
        data={fetchData}
        /*
         data={(query) =>
         new Promise((resolve, reject) => {
         console.log('data query:', query);
         // const searchParams = getSearchParams(query.filters);
         const searchParams = '';
         const url = `${apiUrl}?&page=${query.page + 1}&page_size=${query.pageSize}&search=${searchParams || ' '}`;
         // data: result._embedded.publications.filter(pub => pub.firstAuthor.contains(query.search)),
         gertrudeApi
         .get(url)
         .then((result) => {
         console.log('data res:', result);
         resolve({
         data: result.data.data,
         page: query.page,
         totalCount: result.data.totalCount,
         });
         })
         .catch((e) => {
         console.log('Aua', e);
         reject('fetch all went wrong');
         });
         })
         }
         */
        columns={state.columns}
        actions={[
          (rowData) => {
            return {
              icon: rowData.checked ? tableIcons['Checked'] : tableIcons['Help'],
              tooltip: rowData.checked ? 'Geprüft' : 'Ungeprüft',
              onClick: (event, row) => actionPosChecked(row),
            };
          },
          {
            icon: tableIcons['Export'],
            isFreeAction: true,
            tooltip: 'Download Liste',
            onClick: () => props.exportList(state.searchParams),
          },
          {
            icon: tableIcons['Refresh'],
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
          },
        ]}
        editable={{
          onRowUpdateCancelled: () => {
            enqueueSnackbar('Edit canceled.', { variant: 'info' });
          },
          onRowAddCancelled: () => {
            enqueueSnackbar('Add canceled.', { variant: 'info' });
          },
          onRowDeleteCancelled: () => {
            enqueueSnackbar('Delete canceled.', { variant: 'info' });
          },
          onRowDelete: (rowData) => {
            return new Promise((resolve, reject) => {
              console.log('delete row', rowData, lock);
              props
                .deleteRow(rowData, lock)
                .then((res) => {
                  if (res) {
                    console.debug(`table delete row `, rowData);
                    resolve();
                  } else {
                    console.log(`onRowDelete ups: `, res);
                    reject();
                  }
                })
                .catch(() => reject());
            });
          },
          // does not work here as no document _id is available in the form...
          // needs some dialog to choose a document _id for the new row/Einzelnachweis
          /*
           onRowAdd: (newData) =>
           new Promise((resolve, reject) => {
           props
           .addRow(newData)
           .then((res) => {
           if (res) {
           resolve();
           removeLock();
           } else {
           reject();
           }
           })
           .catch(() => reject());
           }),
           */
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              console.log('onRowUpdate', newData, oldData);
              props
                .updateRow(newData, oldData, lock)
                .then((res) => {
                  if (res) {
                    resolve(newData);
                    removeLock();
                  } else {
                    reject(false);
                  }
                })
                .catch((e) => {
                  console.log('Aua', e);
                  removeLock();
                  reject(false);
                });
            }),
        }}
        components={{
          // eslint-disable-next-line react/display-name
          EditRow: (props) => <MyTableEditRow onEdit={applyLock} offEdit={removeLock} {...props} />,
          // eslint-disable-next-line react/display-name
          Row: (props) => (
            <MTableBodyRow
              {...props}
              // see https://stackoverflow.com/questions/62316709/material-table-make-row-editable-on-click
              onDoubleClick={(e) => {
                console.log('onDoubleClick', props);
                // from SO: props.actions[1]().onClick(e, props.data);
                // but here are two extra actions, so ...
                // eslint-disable-next-line react/prop-types
                props?.actions[4]().onClick(e, props?.data);
              }}
            />
          ),
        }}
        onChangeRowsPerPage={(pageSize) => {
          if (pageSizeOptions.includes(pageSize)) setState({ ...state, pageSize });
        }}
        onOrderChange={(orderBy, orderDirection) => {
          console.debug('onOrderChange', orderBy, orderDirection);
          setSortBy({ orderBy: orderBy, orderDirection: orderDirection });
        }}

        // title={`Summe Korrekturen: ${currencyFormat(state.korrekturSum)} ; Geprüft: ${state.checkedCount} von ${state.data.length}`}
        // title=<TableTitle infos={extraInfo} rows={state.data.length} />
      />
    </>
  );
};
ClaimPositionsTableRaw.propTypes = {
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  exportList: PropTypes.func.isRequired,
  actions: PropTypes.array,
};

/*
 const LoadingIndicator = () => {
 const classes = useStyles();
 return (
 <div className={classes.loading}>
 <CircularProgress color="secondary" />
 </div>
 );
 };

 const isLoadingConditionFn = (props) => props.isFetching;

 const withConditionalRenderings = compose(withEither(isLoadingConditionFn, LoadingIndicator));
 const withConditionalRenderings = compose(
 withEither(isLoadingConditionFn, LoadingIndicator),
 withMaybe(nullConditionFn),
 withEither(isEmptyConditionFn, EmptyMessage)
 );
 const ClaimPositionsTableFromDB = withConditionalRenderings(ClaimPositionsTableRaw);

 */

const ClaimPositionsTableFromDB = ClaimPositionsTableRaw;

// const ClaimPositionsTable = PureClaimPositionsTable;
export default ClaimPositionsTableFromDB;
