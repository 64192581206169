import { parseJSON } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

/***
 * converts a string to a date & time object, if possible.
 * assuming the timezone is local (to the browser) if not specified.
 *
 * Which most databases don't but store them in UTC anyways...
 *
 * @param date_str the string to parse as a date
 * @returns {Date|*} the parsed datetime or the passed string itself
 */
export const as_datetime = (date_str) => {
  // let parsed = parseJSON(date_str);
  // const parsed = zonedTimeToUtc(date_str, 'Europe/Berlin');
  const parsed = zonedTimeToUtc(date_str, 'UTC');
  // console.debug('as_datetime', date_str, parsed);
  return parsed.toString() !== 'Invalid Date' ? parsed : date_str;
};

/**
 * Parses a string of a datetime and returns a datetime for noon in the local Timezone(TZ) at that day.
 * A HACK to deal with local TZ in JS, as JS in a browsers returns the UTC time when asked for a string of a Date object,
 * but not the TZ of the original Date, so the day od that date in UTC can change from the on in the Date Object.
 * FFFFFUUUUU JavaScript.
 *
 * @param date_str
 *
 *  * @returns {Date|*} the parsed date or the passed string itself
 */
export const as_date = (date_str) => {
  const parsed = parseJSON(date_str);
  if (parsed.toString() !== 'Invalid Date') {
    // const offset = new Date().getTimezoneOffset();
    // const nix = new Date(parsed.setHours(-offset / 60, -offset % 60, 0, 0));
    // console.debug(`as_date ${date_str} `, nix);
    return new Date(parsed.setHours(12, 0, 0, 0));
  } else {
    return date_str;
  }
};

export const as_bool = (bool_str) => {
  if (bool_str === false || bool_str === 'false' || bool_str === 0 || bool_str === '0' || bool_str === undefined || bool_str === null) {
    return false;
  }
  if (bool_str === true || bool_str === 'true' || bool_str === '1' || bool_str !== 0) {
    return true;
  }
  return bool_str;
};

export const sanitizeDateTimes = (obj) => {
  const datetimeList = ['lastModified', 'startTime', 'createdAt', 'firstModified', 'lastModified_doc', 'mergedAt'];
  // const dateList = ['geburtstag', 'geburtsdatum', 'endTime', 'brief_datum', 'rechnung_datum'];
  const dateList = ['endTime', 'brief_datum', 'rechnung_datum', 'datum_bis', 'datum_eingang', 'datum_von', 'geburtsdatum', 'geburtstag'];
  Object.keys(obj).forEach((key) => {
    if (datetimeList.includes(key)) {
      obj[key] = as_datetime(obj[key]);
    }
    if (dateList.includes(key)) {
      obj[key] = as_date(obj[key]);
    }
  });
  return obj;
};

let myId = 0;

export const addId = (list) => {
  return list.map((obj) => ({ ...obj, id: myId++ }));
};

export const sanitizeList = (list) => {
  for (let row of list) {
    row = sanitizeDateTimes(row);
    row['checked'] = as_bool(row?.checked);
    // row['error'] = row?.error || [];
  }
  // console.log('sanitizeList', list);
  return list;
};

export const getLock = async (api, resource) => {
  const lockUrl = '/lock';
  const resp = await api.get(`${lockUrl}/${resource}`);
  console.log('getLock', resp.data);
  return resp.data;
  // return { resource: resource, id: 'huhu' };
};

export const deleteLock = async (api, lock) => {
  const lockUrl = '/lock';
  if (!lock.resource) {
    console.log('empty lock?');
    return true;
  }
  try {
    await api.delete(`${lockUrl}/${lock.resource}/id/${lock.id}`);
  } catch (e) {
    console.log('deleteLock', e);
    return false;
  }
  console.log('deleteLock', lock);
  return true;
};

export const prettyErrorMsg = (response) => {
  console.log('prettyError for ', response);
  // error_description from keycloak, status Text from API and rest of errors...
  return response?.response?.data?.error_description || response?.response?.statusText || '';
};

/**
 * Avoid confusion by local timezones.
 *
 * Browsers always use local time, but send utc time to the server, but not the Timezone or offset.
 * So convert each local datetime to an UTC timestamp at Midnight of the date in local timezone.
 *
 * .. warning::
 *
 *    Hack. Creates a new Date Object from the date (Year, Month, day of month) at UTC Midnight
 *
 *
 * @param dateValue a Date Object to fix the timezone offset.
 * @returns {Date|*} Returns a Date Object or the given :attr:`dateValue` parameter.
 */
export const fixLocalTimeZones = (dateValue) => {
  try {
    const year = dateValue.getFullYear();
    const month = dateValue.getMonth(); // from 0 to 11
    const day = dateValue.getDate(); // getDay is "monday" .... "Sunday"... :/
    const newDate = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    console.log(`fixLocalTimeZones: `, dateValue, newDate);
    return newDate;
  } catch (e) {
    if (e instanceof TypeError) {
      console.debug(`no value for ${dateValue} : `, e.message);
    } else {
      console.log('Ups', e);
    }
    return dateValue;
  }
};
