import React, { useEffect, useState } from 'react';
import gertrudeApi from './axios-middleware';
import { CancelToken } from 'axios';

export const useLocalState = (storageKey, initialValue) => {
  const [value, setValue] = React.useState(localStorage.getItem(storageKey) || initialValue);
  React.useEffect(() => {
    localStorage.setItem(storageKey, value);
  }, [value, storageKey]);
  return [value, setValue];
};

export const useSessionState = (storageKey, initialValue) => {
  const [value, setValue] = React.useState(JSON.parse(sessionStorage.getItem(storageKey)) || initialValue);
  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);
  return [value, setValue];
};

export const useApi = (apiUrl, enqueueSnackbar, history) => {
  const [apiResult, setApiResult] = useState();
  useEffect(() => {
    const cancelSource = CancelToken.source();
    const fetchList = async () => {
      // console.debug('useEffect fetchList ');
      try {
        const response = await gertrudeApi.get(apiUrl, { cancelToken: cancelSource.token });
        console.group('useApi fetched data');
        console.log('response', response);
        console.groupEnd();
        setApiResult(response.data);
      } catch (err) {
        // login failed, redirect to login pager
        if (err && err?.response && (err.response.status === 400 || err.response.status === 401 || err.response.status === 403)) {
          enqueueSnackbar('Not logged in.', { variant: 'warning' });
          history.push('/login');
        } else {
          // on any other error go back to home.
          console.group('fetchList Error');
          console.log(err);
          console.groupEnd();
          const errMsg = 'error while reading fax list. ';
          enqueueSnackbar(errMsg, { variant: 'error' });
          history.push('/');
        }
      }
    };
    fetchList().then(() => {
      console.log('fetched List.');
    });
    return () => {
      // cleanup stuff
      console.log('cancel useApi api call to ' + apiUrl);
      cancelSource.cancel('Cancelling previous useApi api call to ' + apiUrl);
    };
  }, [apiUrl, enqueueSnackbar, history]);
  return apiResult;
};
