import React from 'react';
import { parseJSON } from 'date-fns';

// eslint-disable-next-line react/display-name
export const withMaybe = (conditionalRenderingFn) => (Component) => (props) =>
  conditionalRenderingFn(props) ? null : <Component {...props} />;

// eslint-disable-next-line react/display-name
export const withEither = (conditionalRenderingFn, EitherComponent) => (Component) => (props) =>
  conditionalRenderingFn(props) ? <EitherComponent /> : <Component {...props} />;
withEither.displayName = 'withEither';

export const deepEqual = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
};

export const diffObj = (one, two) => {
  return Object.keys(two).reduce((diff, key) => {
    if (one[key] instanceof Date || two[key] instanceof Date) {
      const twoDate = parseJSON(two[key]).getTime();
      const oneDate = parseJSON(one[key]).getTime();
      if (Math.abs(oneDate - twoDate) < 3) return diff;
      else {
        return {
          ...diff,
          [key]: two[key],
        };
      }
    }
    if (one[key] === two[key]) return diff;
    else {
      return {
        ...diff,
        [key]: two[key],
      };
    }
  }, {});
};
