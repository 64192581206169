import MaterialTable from '@material-table/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { amber, green, red } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ArrowDownward } from '@material-ui/icons';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React, { forwardRef, useEffect, useState } from 'react';
import { withEither, withMaybe } from '../helpers/functional';
import { useSessionState } from '../helpers/hooks';
import {
  ampel_lookup,
  ampelBackgroundColor,
  Bar,
  getNavigatorLanguage,
  localization,
  MaterialTableAmpelRender,
  MaterialTableDateRender,
} from '../helpers/material-table-helpers';
import ClaimPositions from './ClaimPositions';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
    },
    normRow: {
      backgroundColor: theme.palette.secondary.main,
    },
    highRow: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  rowAmpelGreen: {
    background: `linear-gradient(to bottom, ${green['600']}, ${theme.palette.background.paper} 100px)`,
  },
  rowAmpelYellow: {
    background: `linear-gradient(to bottom, ${amber['600']}, ${theme.palette.background.paper} 100px)`,
  },
  rowAmpelRed: {
    background: `linear-gradient(to bottom, ${red['600']}, ${theme.palette.background.paper} 100px)`,
  },
}));

const COLUMNS_DEFAULT_SORT_KEY = 'defaultSort';

const navLang = getNavigatorLanguage();
const intlDateTimeoptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};
const intlDateTimeFormat = new Intl.DateTimeFormat(navLang, intlDateTimeoptions);

const iconsMap = {
  Add: AddBox,
  Check,
  Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage,
  LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search,
  SortArrow: ArrowDownward,
  ThirdStateCheck: Remove,
  ViewColumn,
};
const tableIcons = Object.entries(iconsMap).reduce((acc, [iconName, IconComponent]) => {
  // eslint-disable-next-line react/display-name
  acc[iconName] = forwardRef((props, ref) => <IconComponent {...props} ref={ref} />);
  return acc;
}, {});

const currencySetting = {
  locale: navLang,
  currencyCode: 'eur',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const DetailPanel = ({ rowData }) => {
  console.log('huhu', rowData);
  return (
    <>
      {/*<pre>{JSON.stringify(rowData, null, 2)}</pre>*/}
      <ClaimPositions oid={rowData['_id']} slim={true} />
    </>
  );
};
DetailPanel.propTypes = {
  rowData: PropTypes.object.isRequired,
};

const ScanListTableRaw = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [tableFilters, setTableFilters] = useSessionState('scanListFilters', []);

  const errorStyle = React.useCallback(
    (color) => ({
      background: `linear-gradient(to bottom, ${color}, ${theme.palette.background.paper} 25px)`,
      opacity: 0.6,
    }),
    [theme.palette.background.paper]
  );

  /*
   "import_id": "bla-20200511162723",
   "lastModified": "2020-09-02T11:01:06.074000",
   "parser": "blub",
   "rechnung_nr": 123456,
   "filename": "20200511162723.pdf",
   "brief_datum": "2020-03-30T00:00:00",
   "betrag_zahlbetrag": 1234,
   "ik_nr": 1234567890,
   "createdAt": "2020-09-02T11:01:06.065000",
   "rechnung_betrag": 2345.56,
   "_id": "5f4f7b725bac0a6caac9ae8d",
   "zeichen": 10400000025119592,
   "betrag_korrektur": 456.78,
   "rechnung_datum": "2019-11-30T00:00:00"
   */
  const initColumns = React.useMemo(
    () => [
      {
        title: 'Id',
        field: 'import_id',
        hidden: true,
        editable: 'never',
        grouping: false,
      },
      {
        title: 'Ampel',
        field: 'ampel',
        lookup: ampel_lookup,
        // eslint-disable-next-line react/display-name
        render: (rowData) => <MaterialTableAmpelRender value={rowData || null} col={'ampel'} />,
      },
      {
        title: 'Fortschritt',
        field: 'einzelnachweis_checked',
        // eslint-disable-next-line react/display-name
        render: (rowData) => <Bar total={rowData?.einzelnachweis_length} left={rowData?.einzelnachweis_checked} />,
        type: 'numeric',
      },
      {
        title: 'Tage seit Eingang',
        field: 'days_since',
        type: 'numeric',
      },
      {
        title: 'Kasse',
        // field: 'parser',
        field: 'kk_name',
        editable: 'never',
        grouping: true,
      },
      {
        title: 'Zeichen',
        field: 'zeichen',
        editable: 'always',
        cellStyle: (value) => (!value ? errorStyle(red['500']) : null),
      },
      {
        title: 'Rechnungs Liste',
        field: 'rechnungsliste',
        editable: 'never',
      },
      {
        title: 'Betrag',
        field: 'rechnung_betrag',
        editable: 'never',
        type: 'currency',
        currencySetting: currencySetting,
        cellStyle: (value) => (!value ? errorStyle(red['500']) : null),
      },
      {
        title: 'Korrektur',
        field: 'betrag_korrektur',
        editable: 'never',
        type: 'currency',
        currencySetting: currencySetting,
        cellStyle: (value) => (!value ? errorStyle(red['500']) : null),
      },
      {
        title: 'Zahl Betrag',
        field: 'betrag_zahlbetrag',
        editable: 'never',
        type: 'currency',
        currencySetting: currencySetting,
        cellStyle: (value) => (!value ? errorStyle(red['500']) : null),
      },
      {
        title: 'Rechnungsdatum',
        field: 'rechnung_datum',
        editable: 'never',
        type: 'date',
        // eslint-disable-next-line react/display-name
        render: (rowData) => <MaterialTableDateRender value={rowData || null} col={'rechnung_datum'} />,
      },
      {
        title: 'Brief Datum',
        field: 'brief_datum',
        filtering: false,
        // eslint-disable-next-line react/display-name
        render: (rowData) => <MaterialTableDateRender value={rowData || null} col={'brief_datum'} />,
        cellStyle: (value) => (!value ? errorStyle(red['500']) : null),
      },
      {
        title: 'Eingelesen am',
        field: 'createdAt',
        filtering: false,
        // eslint-disable-next-line react/display-name
        render: (rowData) => <MaterialTableDateRender value={rowData || null} col={'createdAt'} />,
      },
      {
        title: 'Letzte Änderung',
        field: 'lastModified',
        filtering: false,
        grouping: false,
        type: 'datetime',
        render: (rowData) =>
          rowData.lastModified instanceof Date ? intlDateTimeFormat.format(rowData.lastModified) : rowData.lastModified,
        // eslint-disable-next-line react/display-name
        // render: (rowData) => <MaterialTableDateRender value={rowData || null} col={'lastModified'} />,
      },
      /*
       {
       title: 'Fehler',
       field: 'fails',
       grouping: false,
       editable: 'never',
       // eslint-disable-next-line react/display-name
       render: (rowData) => <MaterialTableListRender value={rowData || null} col={'fails'} />,
       options: {
       draggable: false,
       },
       },
       {
       title: 'OCR data',
       field: 'has_ocr',
       type: 'boolean',
       // eslint-disable-next-line react/display-name
       render: (rowData) => <FaxListBoolRender value={rowData} col={'has_ocr'} />,
       },
       */
    ],
    [errorStyle]
  );

  const ampelCol = initColumns.map((e) => e.field).indexOf('ampel');
  const [tableSort, setTableSort] = useSessionState('scanListOrder', { orderBy: ampelCol, orderDirection: 'asc' });

  const [state, setState] = useState({
    columns: initColumns,
    data: props.scanList,
    // selected: false,
    // selectedRowId: null,
    // currentRow: {},
  });

  useEffect(() => {
    /**
     * add saved filter values to column definitions
     */
    const applyFilters = (realColumns, filterList) => {
      // console.debug('applyFilters', filterList);
      return filterList
        ? realColumns.map((col) => {
            const fil = filterList.find((f) => f.field === col.field);
            if (fil) {
              // set table filter from given list
              col.defaultFilter = fil.value;
            } else {
              delete col.defaultFilter;
            }
            return col;
          })
        : [...realColumns];
    };
    const key = tableSort?.orderBy || parseInt(tableSort?.orderBy) > 0 ? tableSort.orderBy : 0;
    // console.debug(`ordering by ${key} ${tableSort?.orderDirection}`);
    const columns = applyFilters(initColumns, tableFilters).map((c, i) => {
      if (i !== key) {
        delete c[COLUMNS_DEFAULT_SORT_KEY];
      } else {
        c[COLUMNS_DEFAULT_SORT_KEY] = tableSort.orderDirection;
      }
      return c;
    });
    setState((prevState) => {
      return { ...prevState, columns };
    });
  }, [initColumns, tableFilters, tableSort]);

  console.log('ScanListTableRaw render:', state);
  return (
    <>
      {/*<pre>{JSON.stringify(props.faxList.map(a => a.meta), null, 2)}</pre>*/}
      <MaterialTable
        icons={tableIcons}
        className={classes.root}
        options={{
          idSynonym: '_id',
          grouping: true,
          filtering: true,
          pageSize: 50,
          pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000],
          emptyRowsWhenPaging: false,
          paginationPosition: 'both',
          rowStyle: (rowData) => ({
            background: `linear-gradient(to right, ${ampelBackgroundColor(rowData.ampel)}, ${theme.palette.background.paper} 100px)`,
          }),
        }}
        title='Liste der Reklamationen'
        columns={state.columns}
        localization={localization}
        data={[...props.scanList]}
        detailPanel={(rowData) => <DetailPanel rowData={rowData.rowData} />}
        /*
         editable={{
         onRowAdd: newData =>
         new Promise(resolve => {
         setTimeout(() => {
         resolve();
         const data = state.data;
         data.push(newData);
         setState({...state, data});
         }, 600);
         }),
         onRowUpdate: (newData, oldData) =>
         new Promise(resolve => {
         setTimeout(() => {
         resolve();
         const data = state.data;
         data[data.indexOf(oldData)] = newData;
         setState({...state, data});
         }, 600);
         }),
         onRowDelete: oldData =>
         new Promise(resolve => {
         setTimeout(() => {
         resolve();
         const data = state.data;
         data.splice(data.indexOf(oldData), 1);
         setState({...state, data});
         }, 600);
         })
         }}
         */
        onRowClick={(event, rowData) => {
          console.group('row click');
          console.log(rowData);
          console.groupEnd();
          setState({ ...state });
          /*
           if (rowData.tableData.id === state.selectedRowId) {
           setState({ ...state, selected: false, selectedRowId: null });
           } else {
           setState({
           ...state,
           selected: true,
           selectedRowId: rowData.tableData.id,
           });
           }
           */
          props.changeClaim(rowData['_id']);
        }}
        onSearchChange={(term) => {
          console.log('onSearchChange', term);
        }}
        onOrderChange={(orderBy, orderDirection) => {
          // console.debug('onOrderChange', orderBy, orderDirection);
          setTableSort({ orderBy: orderBy, orderDirection: orderDirection });
        }}
        onFilterChange={(filters) => {
          const filterValues = filters.map((f) => ({ field: f.column.field, value: f.value }));
          setTableFilters(() => filterValues);
          // console.debug('onFilterChange', filterValues);
        }}
        /*
         components={{
         EditField: (fieldProps) => {
         const {
         columnDef: { lookup },
         } = fieldProps;
         if (lookup) {
         console.info(fieldProps);
         return <Autocomplete {...fieldProps} />;
         } else {
         return <MTableEditField {...{ ...fieldProps, value: fieldProps.value || '' }} />;
         }
         },
         }}
         */
      />
    </>
  );
};
ScanListTableRaw.propTypes = {
  scanList: PropTypes.arrayOf(PropTypes.object),
  changeClaim: PropTypes.func,
};

/*
 const LoadingIndicator = () => (
 <div>
 <CircularProgress color='secondary' />
 </div>
 );
 */

const EmptyMessage = () => (
  <Card>
    <CardContent>
      <Typography variant='h5' component='h2'>
        No Matches
      </Typography>
      <Typography variant='body2'>Try some other filters.</Typography>
    </CardContent>
  </Card>
);

// const isLoadingConditionFn = (props) => props.isFetching;
const nullConditionFn = (props) => !props.scanList;
const isEmptyConditionFn = (props) => !props.scanList.length;

const withConditionalRenderings = compose(
  // withEither(isLoadingConditionFn, LoadingIndicator),
  withMaybe(nullConditionFn),
  withEither(isEmptyConditionFn, EmptyMessage)
);

const ScanListTable = withConditionalRenderings(ScanListTableRaw);
export default ScanListTable;
