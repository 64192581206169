import React from 'react';
import PropTypes from 'prop-types';
import '@fontsource/roboto';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Avatar, Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
// import { red } from '@material-ui/core/colors';
import StatusVerfallen from '../assets/status/tot.png';
// import keinImg from '../../public/status/kein.png';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    width: 103,
    // height: 76,
    // paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: theme.palette.primary.main, // backgroundColor: '#102F59',
    color: theme.palette.primary.contrastText,
  },
  avatarOptional: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  gridCardRoot: {
    '& .MuiCard-root': {
      height: '100%',
      width: 'calc(100% - 4px)',
    },
  },
  gridSpacing: {
    '& .MuiTextField-root': {
      paddingRight: theme.spacing(2),
      border: '1px dotted red',
    },
  },
  optionalRoot: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    '& .MuiCollapse-wrapperInner': {
      backgroundColor: theme.palette.background.dark,
    },
    '& .MuiAccordionDetails-root': {
      padding: '8px 0px 8px 8px',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    justifyContent: 'left',
    columnGap: theme.spacing(6),
    rowGap: theme.spacing(2),
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'baseline',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '&:last-child': {
      // background: 'purple',
      paddingBottom: theme.spacing(1),
    },
  },
}));

const LegendListItem = ({ nr, text }) => {
  return (
    <ListItem alignItems='center'>
      <ListItemAvatar>
        <Avatar alt={nr.toString()}>{nr}</Avatar>
      </ListItemAvatar>
      {text}
    </ListItem>
  );
};
LegendListItem.propTypes = {
  nr: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

const Home = () => {
  const classes = useStyles();

  const legendTexts = [
    'Anzeige für Reklamation ist Gut.',
    'Anzeige für Reklamation ist Falsch',
    'Aktions-Button um Status zu wechseln, wenn der aktuelle Status Ok ist.',
    'Aktions-Button um Status zu wechseln, wenn der Status bislang unbekannt oder Falsch ist.',
    'Filter für Spalte &quot;Geprüft&quot;, schaltet um zwischen Gut und Schlecht.',
    'Knopf um die Zeile zu ändern.',
    'Knopf um die Zeile zu löschen.',
  ];
  return (
    <>
      <Grid
        className={classes.gridCardRoot}
        container
        spacing={1}
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        alignContent='stretch'
      >
        <Grid item id='sec_a' xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <Avatar aria-label='section' className={classes.avatar}>
                  <HelpOutlineIcon />
                </Avatar>
              }
              title='Legende'
              subheader='Farben und Icons'
            />
            <CardContent>
              <Grid container>
                <Grid id='keineRegel' container spacing={2}>
                  <Grid item>
                    <img className={classes.media} src={process.env.PUBLIC_URL + '/status/kein.png'} alt='Keine Regel' />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={1}>
                      <Grid item xs>
                        <Typography gutterBottom variant='h6' component='h2'>
                          Kein Verfallsdatum
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2' color='textSecondary'>
                          Reklamationen verfallen nie.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id='AmpelGrün' container spacing={2}>
                  <Grid item>
                    <img className={classes.media} src={process.env.PUBLIC_URL + '/status/grün.png'} alt='Verfallen' />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={1}>
                      <Grid item xs>
                        <Typography gutterBottom variant='h6' component='h2'>
                          Ampel Status Grün
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2' color='textSecondary'>
                          Verfallsdatum ist noch in weiter Ferne.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id='AmpelGelb' container spacing={2}>
                  <Grid item>
                    <img className={classes.media} src={process.env.PUBLIC_URL + '/status/gelb.png'} alt='Keine Regel' />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={1}>
                      <Grid item xs>
                        <Typography gutterBottom variant='h6' component='h2'>
                          Ampel Status Gelb
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2' color='textSecondary'>
                          Nur noch wenige Tage bis zum Verfall.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id='AmpelRot' container spacing={2}>
                  <Grid item>
                    <img className={classes.media} src={process.env.PUBLIC_URL + '/status/rot.png'} alt='Keine Regel' />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={1}>
                      <Grid item xs>
                        <Typography gutterBottom variant='h6' component='h2'>
                          Ampel Rot Status
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2' color='textSecondary'>
                          Fast keine Tage bis zum Verfall.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id='AmpelVerfallen' container spacing={2}>
                  <Grid item>
                    <img className={classes.media} src={StatusVerfallen} alt='Verfallen' />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={1}>
                      <Grid item xs>
                        <Typography gutterBottom variant='h6' component='h2'>
                          Verfallen
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2' color='textSecondary'>
                          Reklamation ist über dem Verfallsdatum.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id='unbekannteRegel' container spacing={2}>
                  <Grid item>
                    <img className={classes.media} src={process.env.PUBLIC_URL + '/status/unbekannt.png'} alt='unbekannt' />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={1}>
                      <Grid item xs>
                        <Typography gutterBottom variant='h6' component='h2'>
                          Unbekannter Status
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2' color='textSecondary'>
                          Wenn keine Regeln für diese Krankenkasse bekannt sind oder berechnet wurden weil z.B. das Rechnungs-Datum fehlt.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item id='sec_b' xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <Avatar aria-label='section' className={classes.avatar}>
                  <HelpOutlineIcon />
                </Avatar>
              }
              title='Legende'
              subheader='Icons in Tabelle'
            />
            <CardContent>
              <Grid container>
                <Grid id='keineRegel' container spacing={2}>
                  <Grid item>
                    <img
                      className={classes.media}
                      style={{ width: 183, height: 283 }}
                      src={process.env.PUBLIC_URL + '/table-head.png'}
                      alt='Tabellen Kopf'
                    />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={1}>
                      <Grid item xs>
                        <Typography gutterBottom variant='h6' component='h2'>
                          Tabellen Ansicht
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <List>
                          {legendTexts.map((e, i) => (
                            <LegendListItem key={i} nr={i + 1} text={e} />
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <small>
        {/* eslint-disable-next-line no-undef */}
        You are running this application in <b>{process.env.NODE_ENV}</b> mode.{' '}
      </small>
    </>
  );
};

Home.propTypes = {
  // from router
  match: PropTypes.object,
  location: PropTypes.object,
};

export default Home;
