import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Edit from '@material-ui/icons/Edit';
import SaveAlt from '@material-ui/icons/SaveAlt';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Search from '@material-ui/icons/Search';
import { ArrowDownward } from '@material-ui/icons';
import Remove from '@material-ui/icons/Remove';
import ViewColumn from '@material-ui/icons/ViewColumn';
import React, { forwardRef } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import HelpIcon from '@material-ui/icons/Help';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SnoozeIcon from '@material-ui/icons/Snooze';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AlarmIcon from '@material-ui/icons/Alarm';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { deepOrange, blue, green, amber, red, grey, purple } from '@material-ui/core/colors';

const iconsMap = {
  Add: AddBox,
  Check,
  Checked: DoneOutlineIcon,
  Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Download: GetAppIcon,
  Edit,
  ErrorOutline: ErrorOutlineIcon,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage,
  Help: HelpIcon,
  LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  Refresh: RefreshIcon,
  ResetSearch: Clear,
  Search,
  SortArrow: ArrowDownward,
  ThirdStateCheck: Remove,
  ViewColumn,
};
export const tableIcons = Object.entries(iconsMap).reduce((acc, [iconName, IconComponent]) => {
  // eslint-disable-next-line react/display-name
  acc[iconName] = forwardRef((props, ref) => <IconComponent {...props} ref={ref} />);
  return acc;
}, {});

export const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';

const navLang = getNavigatorLanguage();

export const currencySetting = {
  locale: navLang,
  currency: 'EUR',
  currencyCode: 'eur',
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const intlDateTimeFormat = new Intl.DateTimeFormat(navLang);
const intlNumberFormat = new Intl.NumberFormat(navLang, currencySetting);
export const currencyFormat = (value) => intlNumberFormat.format(value);

/**
 * Get a color n% between to two given colors.
 *
 * for going from green(120) to red(0):
 * `color = percentageToHsl(perc, 120, 0);`
 *
 * blue(225) to pink(315)
 * blue (225) to yellow(45 + 360)
 *
 * from [SO](https://stackoverflow.com/questions/17525215/calculate-color-values-from-green-to-red/17527156)
 *
 * @param percentage between the start and end color.
 * @param hue0 the start color
 * @param hue1 the end color
 * @returns {string} a hsl() color as string.
 */
export function percentageToHsl(percentage, hue0, hue1) {
  const hue = percentage * (hue1 - hue0) + hue0;
  return 'hsl(' + hue + ', 100%, 50%)';
}

const useBarStyles = makeStyles((theme) => ({
  BarChart: {
    backgroundColor: theme.palette.backgroundColor,
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'row',
    height: '1.5rem',
    width: '100%',
    overflow: 'hidden',
    '& > :first-child': {
      // border: '1px solid orange',
      paddingLeft: theme.spacing(1),
      justifyContent: 'left',
    },
    '& > :last-child': {
      // border: '1px solid purple',
      paddingRight: theme.spacing(1),
      justifyContent: 'right',
    },
  },
  BarData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.6,
    '& span': {
      // backgroundColor: 'green',
    },
  },
}));

export const Bar = (props) => {
  const theme = useTheme();
  const { total, left } = props;

  const classes = useBarStyles();
  const bgColors = [theme.palette.success.main, theme.palette.error.main, theme.palette.primary.main];

  const data = [
    {
      number: left ? Math.floor(left) : null,
      percent: Math.floor((left / total) * 100),
    },
    {
      number: total ? Math.floor(total - left) : null,
      percent: Math.floor(((total - left) / total) * 100),
    },
  ];
  return (
    <div className={classes.BarChart}>
      {data &&
        data.map((d, i) => {
          const style = {
            background: `${bgColors[i]}`,
            // background: `linear-gradient(270deg, ${bgColors[i]}, ${theme.palette.background.paper} 100%)`,
            width: `${Math.floor(d.percent)}%`,
          };
          if (!d.number) style['padding'] = '0';
          return (
            <div key={i} className={classes.BarData} style={style}>
              <Typography variant='body2' component='span' px={1}>
                {d.number}
              </Typography>
            </div>
          );
        })}
    </div>
  );
};
Bar.propTypes = {
  total: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
};

export function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d='M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z' />
    </SvgIcon>
  );
}

export function AlarmLight(props) {
  return (
    <SvgIcon {...props} titleAccess={'Alarm Light'}>
      <path
        fill='currentColor'
        // eslint-disable-next-line max-len
        d='M6,6.9L3.87,4.78L5.28,3.37L7.4,5.5L6,6.9M13,1V4H11V1H13M20.13,4.78L18,6.9L16.6,5.5L18.72,3.37L20.13,4.78M4.5,10.5V12.5H1.5V10.5H4.5M19.5,10.5H22.5V12.5H19.5V10.5M6,20H18A2,2 0 0,1 20,22H4A2,2 0 0,1 6,20M12,5A6,6 0 0,1 18,11V19H6V11A6,6 0 0,1 12,5Z'
      />
    </SvgIcon>
  );
}

export const MaterialTableBoolRender = (props) => {
  const render = (val) => {
    if (val) {
      return <CheckCircleIcon style={{ color: green[100] }} fontSize='small' />;
    } else {
      return <WarningIcon style={{ color: deepOrange[100] }} fontSize='small' />;
    }
  };
  if (typeof props.value === 'boolean') {
    return render(props.value);
  } else if (props.value && props.col in props.value) {
    return render(Boolean(props.value[props.col]) === true);
  }
  return <HelpIcon style={{ color: grey[100] }} fontSize='small' />;
};
MaterialTableBoolRender.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  col: PropTypes.string,
};
MaterialTableBoolRender.displayName = 'FaxListBoolRender';

export const MaterialTableAmpelRender = (props) => {
  const theme = useTheme();
  const render = (val) => {
    switch (val) {
      case 1:
      case 'grün':
        return <CheckCircleIcon style={{ color: theme.palette.success.main }} fontSize='small' />;
      case 2:
      case 'gelb':
        return <SnoozeIcon style={{ color: theme.palette.warning.main }} fontSize='small' />;
      case 3:
      case 'rot':
        return <AlarmIcon style={{ color: theme.palette.error.main }} fontSize='small' />;
      case 4:
      case 'verfristet':
        // return <AlarmLight style={{ color: theme.palette.error.main }} fontSize='small' />;
        return <DeleteForeverIcon style={{ color: 'black' }} fontSize='small' />;
      default:
        return <HelpIcon style={{ color: theme.palette.primary.main }} fontSize='small' />;
    }
  };
  const val = props.value[props.col];
  if (typeof val === 'number' || typeof val === 'string') {
    // console.debug('ampel props', val);
    return render(val);
  }
  return <AlarmOffIcon style={{ color: theme.palette.primary.main }} fontSize='small' />;
};
MaterialTableAmpelRender.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  col: PropTypes.string,
};
MaterialTableAmpelRender.displayName = 'MaterialTableAmpelRender';

export const MaterialTableDateRender = ({ value, col }) => {
  const dt = typeof value === 'number' && value % 1 === 0 ? new Date(value) : value;
  if (dt instanceof Date) {
    // for grouping
    // return dt.toLocaleDateString(navLang);
    return intlDateTimeFormat.format(dt);
    // return new Date(dt.setHours(0, 0, 0, 0)).toLocaleDateString(navLang);
  } else if (value && col in value) {
    // as column
    const d = value[col];
    try {
      return intlDateTimeFormat.format(d);
      //return d.toLocaleDateString(navLang);
    } catch (TypeError) {
      console.log(`${d} in ${col} is not a date.`);
      return d;
    }
  }
  // console.warn('ListDateRender called with useless data:', props);
  return 'n/a';
};
MaterialTableDateRender.propTypes = {
  /**
   * an Object with the table row data or a date as Date object or seconds since epoch.
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  // if coming from the renderer an addition parameter for the name of the column o be rendered.
  col: PropTypes.string,
};
MaterialTableDateRender.displayName = 'MaterialTableDateRender';

export const MaterialTableListRender = (props) => {
  const d = props.value[props.col];
  if (Array.isArray(d)) {
    return d.join(', ');
  } else if (d === undefined) {
    return null;
  } else {
    return d;
  }
};
MaterialTableListRender.propTypes = {
  /**
   * an Object with the table row data or ...
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /**
   *  if coming from the renderer an addition parameter for the name of the column to be rendered.
   */
  col: PropTypes.string,
};
MaterialTableListRender.displayName = 'MaterialTableListRender';

export const MTDateRender = (props) => {
  return (
    <KeyboardDatePicker
      format='dd.MM.yyyy'
      value={props.value || null}
      onChange={props.onChange}
      clearable
      autoOk
      disableToolbar={true}
      variant={'inline'}
      margin='dense'
      // InputProps={{ style: { fontSize: 13, }, }}
    />
  );
};
MTDateRender.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  columnDef: PropTypes.object.isRequired,
};

export const localization = {
  body: {
    emptyDataSourceMessage: 'Keine Einträge',
    addTooltip: 'Hinzufügen',
    deleteTooltip: 'Löschen',
    editTooltip: 'Bearbeiten',
    filterRow: {
      filterTooltip: 'Filter',
    },
    editRow: {
      deleteText: 'Diese Zeile wirklich löschen?',
      cancelTooltip: 'Abbrechen',
      saveTooltip: 'Speichern',
    },
  },
  grouping: {
    placeholder: 'Spalten hierher ziehen ...',
    groupedBy: 'Gruppiert nach:',
  },
  header: {
    actions: 'Aktionen',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} von {count}',
    labelRowsSelect: 'Zeilen',
    labelRowsPerPage: 'Zeilen pro Seite:',
    firstAriaLabel: 'Erste Seite',
    firstTooltip: 'Erste Seite',
    previousAriaLabel: 'Vorherige Seite',
    previousTooltip: 'Vorherige Seite',
    nextAriaLabel: 'Nächste Seite',
    nextTooltip: 'Nächste Seite',
    lastAriaLabel: 'Letzte Seite',
    lastTooltip: 'Letzte Seite',
  },
  toolbar: {
    addRemoveColumns: 'Spalten hinzufügen oder löschen',
    nRowsSelected: '{0} Zeile(n) ausgewählt',
    showColumnsTitle: 'Zeige Spalten',
    showColumnsAriaLabel: 'Zeige Spalten',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportName: 'Export als CSV',
    searchTooltip: 'Suche',
    searchPlaceholder: 'Suche',
  },
};

export function LinearProgressWithLabel(props) {
  const { width, ...rest } = props;
  const color = percentageToHsl(props.value / 100, 0, 120);
  const useStyles = makeStyles({
    root: {
      height: 15,
      borderRadius: 3,
    },
    // colorPrimary: {
    //   backgroundColor: 'black',
    // },
    barColorPrimary: {
      backgroundColor: color,
    },
  });
  const classes = useStyles();
  // console.debug('LinearProgressWithLabel', styles);
  return (
    <Box display='flex' alignItems='center'>
      <Box width={width || '100%'} mr={1}>
        <LinearProgress classes={classes} variant='determinate' {...rest} />
      </Box>
      <Box minWidth={'3rem'}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  width: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export const TableTitle = (props) => {
  const { infos, rows } = props;
  return (
    <Box display='flex'>
      <Typography display='inline' variant={'body1'} pr={2}>
        Summe Korrekturen: {currencyFormat(infos.korrekturSum)}
      </Typography>
      <Box px={10}>
        <Typography display='inline' variant={'body1'} pr={2}>
          Geprüft: {infos.checkedCount} von {rows}
        </Typography>
      </Box>
      <LinearProgressWithLabel value={(infos.checkedCount / rows) * 100} width={'25vw'} />
    </Box>
  );
};
TableTitle.propTypes = {
  infos: PropTypes.shape({
    checkedCount: PropTypes.number,
    korrekturSum: PropTypes.number,
  }).isRequired,
  rows: PropTypes.number.isRequired,
};

/**
 * a list for material table to show juman readable strings for the values of the ampel given by the API from the DB.
 *
 * @type {{gelb: string, rot: string, unbekannt: string, verfristet: string, grün: string}}
 */
export const ampel_lookup = {
  grün: 'Grün',
  gelb: 'Gelb',
  rot: 'Rot',
  verfristet: 'Verfallen',
  unbekannt: 'Unbekannt',
  unbefristet: 'Unbefristet',
  lila: 'Keine Regel',
};
// lookup: { grün: 'Grün', gelb: 'Gelb', rot: 'Rot', verfristet: 'Verfristet', 5: 'Unbekannt', 1: '?' },
// lookup: { 1: 'Grün', 2: 'Gelb', 3: 'Rot', 4: 'Verfristet', 5: 'Unbekannt' }

export const ampelBackgroundColor = (ampel) => {
  // console.debug(`Ampel ${ampel}`);
  switch (ampel) {
    case 1:
    case ampel_lookup['grün']:
    case 'grün':
      return green['600'];
    case 2:
    case ampel_lookup['gelb']:
    case 'gelb':
      return amber['600'];
    case 3:
    case 'rot':
    case ampel_lookup['rot']:
      return red['600'];
    case 4:
    case 'schwarz':
    case 'verfristet':
    case ampel_lookup['verfristet']:
      return grey['900'];
    case 0:
    case 'blau':
    case 'unbekannt':
    case ampel_lookup['unbekannt']:
      return blue['200'];
    case 'lila':
    case 'unbefristet':
    case ampel_lookup['unbefristet']:
      return purple['200'];
    default:
      return null;
  }
};

export const errorStyle = (theme) => {
  const start_color = red['500'];
  const end_color = theme.palette.background.paper;
  return {
    background: `linear-gradient(to bottom, ${start_color}, ${end_color} 22px)`,
    opacity: 0.6,
  };
};
