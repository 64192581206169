import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { compose, pipe } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import gertrudeApi from '../helpers/axios-middleware';
import { addId, deleteLock, getLock, sanitizeList } from '../helpers/backend-helpers';
import { diffObj, withEither, withMaybe } from '../helpers/functional';
import useUnload from '../helpers/useUnload';
import ClaimInfos from './ClaimInfos';
import ClaimPositionsTable from './ClaimPositionsTable';
import DownloadFileButton from './DownloadFileButton';
import SimpleClaimsTable from './SimpleClaimsTable';

export const exportApiUrl = '/export/claims/by_oid/';
export const downloadPdfUrl = '/pdf/by_oid/';
const addRowUrl = '/claims/pos/by_oid/';
const deleteRowUrl = '/claims/pos/delete/by_oid/';

const useStyles = makeStyles((theme) => ({
  row: { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
}));

const LOCK_TIMEOUT = 600;

const LockTimer = (props) => {
  const { time } = props; // seconds as set by the Lock in the backend
  // console.debug('lockTimeout', time);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <CountdownCircleTimer
        isPlaying
        trailColor={theme.palette.background.paper}
        duration={time || LOCK_TIMEOUT}
        // initialRemainingTime={time - 10}
        colors={['#004777', '#F7B801', '#A30000']}
        colorsTime={[time / 1, time / 3, time / 10]}
        strokeWidth={5}
        size={50}
        onComplete={() => {
          // console.log("time's up");
          enqueueSnackbar('Sperrung erloschen.', { variant: 'warning' });
        }}
      ></CountdownCircleTimer>
    </>
  );
};
LockTimer.propTypes = {
  time: PropTypes.number,
};
LockTimer.defaults = {
  time: LOCK_TIMEOUT,
};

/*
 "einzelnachweis"
 BEK:
 "beleg" // nennen es aber "Rezept"
 "name"
 "geburtsdatum"
 "korrektur"
 "datum_von"
 "datum_bis"
 "grund"
 AOK:
 "beleg"
 "kvnr"
 "name"
 "forderung"
 "korrektur"
 "grund"
 DAK:
 "beleg"
 "picnr"
 "korrektur"
 "grund"
 "pos"
 "datum"
 "artPosNr"
 "korrektur2"
 "name"
 */

/*
 const removeEmptyColumn = (name, columns, data) => {
 const hasGeburtstag = data.filter((row) => row[name] !== undefined).length > 0;
 if (!hasGeburtstag) {
 // console.debug('keine geburtstage');
 const idx = columns.findIndex((x) => x.field === name);
 if (idx > -1) columns.splice(idx, 1);
 return columns;
 }
 };
 const data = props.positionsList;
 columnsState = removeEmptyColumn('geburtsdatum', columnsState, data)
 columnsState = removeEmptyColumn('beleg', columnsState, data)
 columnsState = removeEmptyColumn('rezept', columnsState, data)
 */

/**
 * A Table with all the einzelnachweis merged with Linde Infos as reported by the REST API.
 *
 * @param props with the list of the merged einzelnachweis and Linde Rechnungsliste
 * @returns {JSX.Element}
 * @constructor
 */
const ClaimPositionsRaw = (props) => {
  const [claim, setClaim] = useState({
    oid: null,
    positionsList: [],
    info: {},
    isFetching: false,
    error: false,
  });
  const [lock, setLock] = useState({});
  const lockRef = useRef(lock);
  const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  // const classes = useStyles(theme);

  /**
   * fetches the xlsx file from the backend and make the browser download it.
   * uses the claim from the state to get the _id of the claim to query the backend API at '/export/claims/by_oid/'
   * see see https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
   * @param: event the click event on the export button, because reasons.
   */
  const exportList = async (event) => {
    console.log('exportList: claim', claim);
    console.log('exportList newData :', event);
    try {
      const response = await gertrudeApi.get(`${exportApiUrl}${claim.oid}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]), {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const link = document.createElement('a');
      link.href = url;
      const dlDate = new Date().toLocaleTimeString('de-DE', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
      link.setAttribute('download', `Rechnungsliste ${claim.info['rechnungsliste']} am ${dlDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      console.log('exportList', response.status);
      enqueueSnackbar(`Download von ${claim.info['rechnungsliste']}`, {
        variant: 'info',
      });
      return response.status === 200;
    } catch (e) {
      console.log('Aua.', e);
      enqueueSnackbar('Fehler beim Download der xlsx Datei: ' + (e.response.statusText || ''), { variant: 'error' });
      return false;
    }
  };

  const fixIsoDates = (obj) => {
    const datetimeList = ['lastModified', 'startTime', 'createdAt', 'firstModified', 'lastModified_doc', 'mergedAt'];
    Object.keys(obj).forEach((key) => {
      if (datetimeList.includes(key)) {
        const parsed = parseJSON(obj[key]);
        if (!isNaN(parsed.getTime())) {
          obj[key] = parsed;
        }
      }
    });
    return obj;
  };

  const updateRowInDB = async (newData, oldData) => {
    console.log('updateRowInDB: claim', claim);
    newData = pipe(fixIsoDates)(newData);
    console.log('updateRowInDB newData:', newData);
    console.log('updateRowInDB oldData:', oldData);
    const diffData = diffObj(oldData, newData);
    console.log('updateRowInDB diffData:', diffData);
    // diffData.startTime.setUTCHours(0, 0, 0, 0);
    // diffData.endTime.setUTCHours(0, 0, 0, 0);
    const apiUrl = '/claims/pos/by_oid/';
    // const row = oldData.tableData.id;
    let o = { ...oldData };
    delete o.tableData;
    const data = {
      oid: claim.oid,
      new_data: diffData,
      old_data: o,
      lock: lock,
    };
    try {
      const response = await gertrudeApi.patch(`${apiUrl}${claim.oid}`, data);
      console.log('updateRowInDB', response);
      const ok = response.status === 200;
      const success = response?.data?.success;
      console.log('updateRowInDB success', success);
      if (ok && success) {
        return true;
      } else {
        enqueueSnackbar('Daten konnten nicht gespeichert werden.', { variant: 'error' });
        return false;
      }
    } catch (e) {
      if (e.response.status === 403 || e.response.status === 400) {
        enqueueSnackbar('Daten konnten nicht gespeichert werden, Sperre oder Login abgelaufen?', { variant: 'error' });
      } else {
        console.log('Aua.', e);
        enqueueSnackbar('Error saving the changes: ' + (e?.response?.statusText || ''), { variant: 'error' });
      }
      return false;
    }
  };

  const addRowInDB = async (newData) => {
    console.log('updateRowInDB: claim', claim);
    console.log('updateRowInDB newData :', newData);
    const data = {
      oid: claim.oid,
      new_data: newData,
      lock: lock,
    };
    try {
      const response = await gertrudeApi.post(`${addRowUrl}${claim.oid}`, data);
      console.log('addRowInDB', response);
      const ok = response.status === 200;
      if (ok) {
        let positionsList = [...claim.positionsList];
        newData['lastModified'] = new Date();
        newData['ez_id'] = response.data?.ez_id;
        positionsList.unshift(newData);
        // setClaim((prev) => {
        //   return { ...prev, positionsList };
        // });
      }
      return ok;
    } catch (e) {
      console.log('Aua.', e);
      enqueueSnackbar('Error saving the changes: ' + (e?.response?.statusText || ''), { variant: 'error' });
      return false;
    }
  };

  const deleteRowInDB = async (oldData) => {
    console.log('deleteRowInDB: claim', claim);
    console.log('deleteRowInDB oldData :', oldData);
    const req_data = {
      oid: claim.oid,
      old_data: { ...oldData },
      lock: lock,
    };
    // stupid material table stuff
    delete req_data.old_data.tableData;
    try {
      const response = await gertrudeApi.post(`${deleteRowUrl}${claim.oid}`, req_data);
      console.log('deleteRowInDB', response);
      const ok = response.status === 200;
      if (ok) {
        console.debug('claim delete row', claim.positionsList);
        let positionsList = [...claim.positionsList];
        // find the position of oldData in the table data:
        // const row = claim.positionsList.indexOf(oldData);
        /*
         const row = claim.positionsList.findIndex((elem) =>
         Object.keys(elem)
         .map((key) => oldData[key] === elem[key])
         .every((x) => x === true)
         );
         */
        const row = claim.positionsList.findIndex((elem) => oldData.id === elem.id);
        if (row >= 0) {
          positionsList.splice(row, 1);
          setClaim({ ...claim, positionsList });
          return true;
        } else {
          console.log('row to delete not found:', oldData);
          enqueueSnackbar('Die Zeile zum Löschen wurde nicht gefunden?', { variant: 'error' });
          return false;
        }
      } else {
        enqueueSnackbar('Die Zeile konnte nicht gelöscht werden.', { variant: 'error' });
      }
      return ok;
    } catch (e) {
      console.log('Aua.', e);
      enqueueSnackbar('Error saving the changes: ' + (e.response.statusText || ''), { variant: 'error' });
      return false;
    }
  };

  const updateClaimInDB = async (newData) => {
    console.log('updateClaimInDB: claim', claim);
    console.log('updateClaimInDB newData:', newData);
    const apiUrl = '/claims/update/by_oid/';
    const data = {
      oid: claim.oid,
      parser: claim.info.parser,
      lock: lock,
      new_data: newData,
    };
    console.debug('updateClaimInDB data', data);
    try {
      const response = await gertrudeApi.patch(`${apiUrl}${claim.oid}`, data);
      console.log('updateClaimInDB ', response);
      if (response.status === 200) {
        claim.info = { ...claim.info, ...newData };
        enqueueSnackbar('Daten gespeichert.', { variant: 'success' });
      } else {
        enqueueSnackbar('Error saving the changes: ' + (response.statusText || ''), { variant: 'error' });
      }
      return response.status === 200;
    } catch (e) {
      console.log('Aua.', e);
      enqueueSnackbar('Error saving the changes: ' + (e?.response?.statusText || ''), { variant: 'error' });
      return false;
    }
  };

  useEffect(() => {
    // see https://www.robinwieruch.de/react-hooks-fetch-data
    const fetchList = async (oid) => {
      if (!props.slim) {
        // slim ist just for viewing...
        const lock_data = await getLock(gertrudeApi, oid);
        setLock(lock_data);
        // lockRef.current = lock;
      }
      console.log('claimPositions fetchList', oid);
      const apiUrl = '/claims/by_oid/';
      setClaim({
        oid: oid,
        positionsList: [],
        info: {},
        isFetching: true,
        error: false,
      });
      try {
        return await gertrudeApi.get(`${apiUrl}${oid}`);
      } catch (e) {
        if (e.response && (e.response.status === 401 || e.response.status === 403)) {
          enqueueSnackbar('Not logged in.', { variant: 'warning' });
        } else if (e.response && e.response.status === 404) {
          enqueueSnackbar('Keine gültigen Ergebnisse gefunden.', { variant: 'warning' });
        } else {
          let errMsg = 'error while reading fax list';
          // let path = '/';
          enqueueSnackbar(errMsg, { variant: 'error' });
          console.group('fetchList Error');
          if (lockRef?.current?.resource) {
            await deleteLock(gertrudeApi, lockRef.current);
            console.log('lock', lockRef?.current);
          }
          console.warn(e);
          console.groupEnd();
        }
      }
    };
    const oid = props?.match?.params?.oid ? props.match.params.oid : props.oid;
    if (oid) {
      fetchList(oid)
        .then((response) => {
          console.group('claimPositions fetched data');
          try {
            console.log('Got data.', response);
            const positionsList = pipe(addId, sanitizeList)(response.data['einzelnachweis']);
            // eslint-disable-next-line no-unused-vars
            const claimInfo = (({ einzelnachweis, ...o }) => o)(response.data); // remove einzelnachweis
            console.log('positionsList', positionsList);
            console.log('claimInfo', claimInfo);
            console.groupEnd();
            setClaim((prevState) => ({
              ...prevState,
              oid: oid,
              positionsList: positionsList,
              info: claimInfo,
              isFetching: false,
              error: false,
            }));
          } catch (e) {
            console.log('fetchList response is broken:', e);
          }
        })
        .catch((e) => {
          if (e.response && [400, 401, 403, 422].includes(e.response.status)) {
            enqueueSnackbar('Reklamation kann nicht gesperrt werden.', { variant: 'warning' });
          } else if (e.response && [404].includes(e.response.status)) {
            enqueueSnackbar('No document.', { variant: 'info' });
          } else {
            enqueueSnackbar('Netzwerk Fehler', { variant: 'error' });
          }
          history.back();
          console.log('fetchList effect catch:', e?.status);
          console.log(e);
        });
    } else {
      console.log('Aua.', oid);
    }
    return () => {
      console.log('ClaimPositions cleanup:', lockRef.current);
      // if (claim.lock.resource) {
      if (lockRef.current && lockRef.current.resource) {
        deleteLock(gertrudeApi, lockRef.current)
          .catch((e) => {
            console.log('Aua.', e);
          })
          .then((r) => {
            console.log('deleteLock:', r);
          });
      } else {
        console.log('ClaimPositions cleanup without lock.');
      }
    }; // cleanup stuff
  }, [props.match, enqueueSnackbar, props.oid, props.slim]);

  useUnload((event) => {
    console.log(`Leaving page`, lockRef?.current);
    // Cancel the event as stated by the standard.
    // event.preventDefault();
    // Chrome requires returnValue to be set.
    // event.returnValue = '';
    // the absence of a returnValue property on the event will guarantee the browser unload happens
    delete event['returnValue'];
    if (lockRef.current && lockRef.current.resource) {
      let fd = new FormData();
      fd.append('resource', lockRef.current.resource);
      fd.append('id', lockRef.current.id);
      navigator.sendBeacon('/unload', fd);
    } else {
      console.warn('No lock.');
    }
  });

  if (!('_id' in claim.info)) {
    console.log('empty claim.');
    return null;
  }
  lockRef.current = lock;
  console.log('render lockRef', lockRef.current);

  return (
    <>
      {/*<pre>{JSON.stringify(claim.positionsList, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(claim, null, 2)}</pre>*/}
      {props.slim ? (
        <SimpleClaimsTable positionsList={claim.positionsList} isFetching={claim.isFetching} />
      ) : (
        <>
          <Box display='flex' flexDirection='row' alignItems='flex-start'>
            <ClaimInfos info={claim.info} updateClaim={updateClaimInDB} />
            <Box display='flex' flexDirection='column' flexGrow={1} pr={2} m={0}>
              <Box display='flex' p={1} m={1} justifyContent='flex-end' alignItems='flex-end'>
                <LockTimer time={LOCK_TIMEOUT} />
              </Box>
              {claim.info?.pdf_file_id ? (
                <Box mt={5} display='flex' alignSelf='flex-end' flexGrow={1}>
                  <DownloadFileButton
                    fileName={claim.info.filename}
                    label={'Download PDF'}
                    url={`${downloadPdfUrl}${claim.info.pdf_file_id}`}
                    type={'application/pdf'}
                  />
                  {/*
                   <Button variant="contained" color="secondary" onClick={() => window.open(`${downloadPdfUrl}${claim.info.pdf_file_id}`)}>
                   */}
                  {/*  Open PDF in Browser*/}
                  {/*</Button>*/}
                </Box>
              ) : null}
            </Box>
          </Box>
          <ClaimPositionsTable
            positionsList={claim.positionsList}
            updateRow={updateRowInDB}
            addRow={addRowInDB}
            deleteRow={deleteRowInDB}
            exportList={exportList}
            isFetching={claim.isFetching}
          />
          {/*<ClaimScanPages import_id={claim.info['import_id']} pages={claim.info['images_length']} />*/}
        </>
      )}
    </>
  );
};
ClaimPositionsRaw.propTypes = {
  oid: PropTypes.string,
  // from router
  match: PropTypes.object,
  location: PropTypes.object,
  slim: PropTypes.bool,
};
ClaimPositionsRaw.defaults = {
  slim: false,
};
ClaimPositionsRaw.displayName = 'ClaimPositions';

const LoadingIndicator = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress color='secondary' />
    </div>
  );
};
// const EmptyMessage = () => <div>Keine Reklamation gefunden.</div>;

const isLoadingConditionFn = (props) => props.isFetching;
const nullConditionFn = (props) => !props.match && !props.location && !props.oid;
// const isEmptyConditionFn = (props) => !props.match && !props.location && !props.oid;

const withConditionalRenderings = compose(
  withEither(isLoadingConditionFn, LoadingIndicator),
  withMaybe(nullConditionFn)
  // withEither(isEmptyConditionFn, EmptyMessage)
);

const ClaimPositions = withConditionalRenderings(ClaimPositionsRaw);
// const ClaimPositionsTable = PureClaimPositionsTable;
export default ClaimPositions;
