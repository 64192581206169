import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { compose } from 'ramda';
import { withEither, withMaybe } from '../helpers/functional';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { getNavigatorLanguage, MaterialTableBoolRender } from '../helpers/material-table-helpers';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const navLang = getNavigatorLanguage();
const intlDateTimeFormat = new Intl.DateTimeFormat(navLang);

const SimpleClaimsTableRaw = (props) => {
  const classes = useStyles();
  const rows = props.positionsList;
  let columns = [
    {
      title: 'Geprüft',
      field: 'checked',
      type: 'boolean',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Fehler erzeugt',
      field: 'error_from',
    },
    {
      title: 'Grund',
      field: 'clusterClaim',
    },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'KV Nummer',
      field: 'kv_nummer',
    },
    /*
    {
      title: 'Geburtsdatum',
      field: 'geburtsdatum',
      type: 'date',
      dateSetting: {
        format: 'dd.MM.yyyy',
      },
    },
    */
    {
      title: 'Rechnung Nr',
      field: 'rechnung_nr',
    },
    {
      title: 'Pos.',
      field: 'pos',
      type: 'numeric',
    },
    {
      title: 'Korrektur',
      field: 'korrektur',
      type: 'numeric',
    },
    {
      title: 'last Modified',
      field: 'lastModified',
      type: 'datetime',
    },
    {
      title: 'Grund (Text)',
      field: 'grund',
    },
  ];
  const render = (value) => {
    switch (typeof value) {
      case 'string':
      case 'number':
        return value;
      case 'boolean':
        return <MaterialTableBoolRender value={value} />;
      case 'object':
        if (value instanceof Date) {
          return intlDateTimeFormat.format(value);
        }
        break;
      default:
        return <>?</>;
    }
    return <>?</>;
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size='small' ariaLabel='list of claims'>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.title}>{col.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, row_idx) => (
            <TableRow key={'row_' + row_idx}>
              {columns.map((col, col_idx) => (
                <TableCell key={'cell_' + row_idx + '_' + col_idx} align={col.type === 'numeric' ? 'right' : 'left'}>
                  {render(row[col.field])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
SimpleClaimsTableRaw.propTypes = {
  positionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const LoadingIndicator = () => (
  <div>
    <CircularProgress color='secondary' />
  </div>
);

const EmptyMessage = () => <div>Keine Einzelnachweise gefunden.</div>;

const isLoadingConditionFn = (props) => props.isFetching;
const nullConditionFn = (props) => !props.positionsList;
const isEmptyConditionFn = (props) => !props.positionsList?.length;

const withConditionalRenderings = compose(
  withEither(isLoadingConditionFn, LoadingIndicator),
  withMaybe(nullConditionFn),
  withEither(isEmptyConditionFn, EmptyMessage)
);

const SimpleClaimsTable = withConditionalRenderings(SimpleClaimsTableRaw);
export default SimpleClaimsTable;
