import React, { useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CardHeader } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AppContext from './contexts/AppContext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginCard: {
    border: '1px solid lightgray',
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(-1),
    marginTop: '20vh',
    maxWidth: '90vw',
    width: '20vw',
    minWidth: '13rem',
    minHeight: '12rem',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const Logout = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useContext(AppContext);
  let history = useHistory();

  useEffect(() => {
    console.log('init LoginForm.');
  }, []);

  const handleLogout = async () => {
    dispatch({ type: 'logout' });
    enqueueSnackbar('User ' + state.user + ' logged out. Bye.', {
      variant: 'success',
      vertical: 'bottom',
      horizontal: 'center',
    });
    history.push('/');
  };

  const handleCancel = async () => {
    console.log('cancel');
    enqueueSnackbar('Canceled logout.', { variant: 'warning', vertical: 'bottom', horizontal: 'center' });
    history.push('/');
  };

  return (
    <>
      <Container maxWidth='xl'>
        <div className={classes.loginWrapper}>
          <Card className={classes.loginCard}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar}>
                  <LockOpenIcon />
                </Avatar>
              }
              title='Logout'
              subheader={'and remove your settings.'}
            />
            <CardContent>
              <Grid container justify='space-between' alignItems='center' alignContent='center'>
                <Grid item>
                  <Button type='submit' onClick={handleLogout} variant='contained' color='primary' size='large' endIcon={<ExitToAppIcon />}>
                    Yes
                  </Button>
                </Grid>
                <Grid item>
                  <Button type='submit' onClick={handleCancel} variant='contained' color='secondary' size='large' endIcon={<CancelIcon />}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </Container>
    </>
  );
};
Logout.propTypes = {
  // setTitle: PropTypes.func.isRequired,
};

export default Logout;
